<template>
  <section>
    <h2>よくある質問</h2>
    <div class="wrap">
      <h2 class="mt-0">◾ パートナーシップについて</h2>

      <h3 class="question"><span class="qa-text">Q</span>パートナー制度について知りたい</h3>
      <p class="answer"><span class="qa-text">A</span>営業先がミナティ各種サービスをご利用いただくことで、契約内容に応じたインセンティブを受け取れる制度のことです。</p>

      <h3 class="question"><span class="qa-text">Q</span>パートナーの仕事について知りたい</h3>
      <p class="answer"><span class="qa-text">A</span>
        ミナティのサービスをご提案いただき、契約までのサポートをしていただきます。<br>
        オーナーさまに合ったプランのご提案、ご利用までのサポートをお願いします。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>営業先について知りたい</h3>
      <p class="answer"><span class="qa-text">A</span>全国の企業・個人事業主さま（主に店舗運営）が対象です。</p>

      <h3 class="question"><span class="qa-text">Q</span>パートナーに適した業種について知りたい</h3>
      <p class="answer"><span class="qa-text">A</span>ミナティシステムのご利用に適した業態と関わりの事業者又は個人、やる気のある方でしたら、パートナーになることができます。スキルや資格は必要ございません。</p>

      <h3 class="question"><span class="qa-text">Q</span>登録用QRコードについて知りたい</h3>
      <p class="answer"><span class="qa-text">A</span>
        営業先さまがミナティに会員登録するためのものです。<br>
        パートナーさまの情報が入った「QRコード」または「URL」から登録していただくことで、紹介先の紐づけが可能になります。
      </p>

      <h2>◾ 費用について</h2>

      <h3 class="question"><span class="qa-text">Q</span>パートナー登録費用について知りたい</h3>
      <p class="answer"><span class="qa-text">A</span>パートナーシップの登録費用は無料です。</p>

      <h3 class="question"><span class="qa-text">Q</span>商品仕入について知りたい</h3>
      <p class="answer"><span class="qa-text">A</span>商品仕入はありません。</p>

      <h3 class="question"><span class="qa-text">Q</span>インセンティブについて知りたい</h3>
      <p class="answer mb-0"><span class="qa-text">A</span>
        詳しくはこちらよりお問い合わせください。
      </p>
      <router-link to="/contact" class="answer underline point-text">お問い合わせフォーム</router-link>

      <h2>◾ サポート体制について</h2>

      <h3 class="question"><span class="qa-text">Q</span>営業ツールについて知りたい</h3>
      <p class="answer"><span class="qa-text">A</span>パートナーさま専用の登録QRコードを印字したパンフレットや説明動画など、営業活動に必要なツールをご提供いたします。</p>

      <h3 class="question"><span class="qa-text">Q</span>研修について知りたい</h3>
      <p class="answer mb-0"><span class="qa-text">A</span>営業の進め方に関するレクチャー講座を実施しています。基本は、オンライン講座で対応しています。企業訪問をご希望の場合は、別途ご相談ください。</p>
      <router-link to="/contact" class="answer underline point-text">お問い合わせフォーム</router-link>

      <h2>◾ 申込について</h2>

      <h3 class="question"><span class="qa-text">Q</span>パートナー登録方法について知りたい</h3>
      <p class="answer mb-0"><span class="qa-text">A</span>以下の手順になります。</p>
      <p class="index">① 説明動画のご視聴</p>
      <p class="index">② オンライン面談</p>
      <p class="index">③ 登録画面の取得（パートナー登録用の申し込みURLを送付）</p>
      <p class="index">④ 必要事項の入力</p>
      <p class="index">⑤ 利用規約の同意</p>
      <p class="index mb-20">⑥ 登録完了（パートナーさま専用の管理画面発行）</p>

      <h3 class="question"><span class="qa-text">Q</span>直接説明が聞きたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>
        <router-link to="/contact" class="underline">お問い合わせフォーム</router-link>よりお問い合わせください。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "PartnerFaqContent",
}
</script>

<style lang="scss" scoped>
  section {
    h2 {
      text-align: center;
    }
    .wrap {
      h2 {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--font);
        margin: 60px 0 20px 0;
        color: var(--font);
      }
      .question {
        font-size: 20px;
        font-weight: 500;
        line-height: 2.5rem;
        margin: 0;
        color: var(--main);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          line-height: 2rem;
          margin-bottom: 5px;
        }
        @media screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 2rem;
        }
      }
      .answer {
        font-size: 20px;
        font-weight: 500;
        color: var(--font);
        margin-top: 0;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      .qa-text {
        margin-right: 1rem;
        font-weight: 600;
      }
      .index {
        font-size: 18px;
        margin-left: 1rem;
        color: var(--font);
      }
      .point-text {
        color: red;
      }
      .underline {
        text-decoration: underline;
      }
      .mt-20 {
        margin-top: 20px;
      }
      .mt-0 {
        margin-top: 0;
      }
      .mb-20 {
        margin-bottom: 20px;
      }
      .mb-0 {
        margin-bottom: 0;
      }
    }
  }
</style>