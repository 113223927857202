<template>
  <section id="movie-check">
    <div class="wrap">
      <div class="content">
        <h2>
          集客を革新する<br>ビジネスツール
          <br class="pc-tablet-only">
          <span class="sp-only">  </span>
          ミナティ
        </h2>
        <div class="movie">
          <img src="@/assets/top/gazo001.webp" alt="集客を革新するビジネスツール ミナティ" class="movie-sp-img">
          <p class="movie-text">サービス内容を動画で！</p>
          <a class="movie-link" v-scroll-to="'#movie-list'">動画視聴</a>
        </div>
      </div>
      <img src="@/assets/top/gazo001.webp" alt="集客を革新するビジネスツール ミナティ" class="movie-img tablet-only">
    </div>
  </section>
</template>

<script>
export default {
  name: "MovieCheck",
}
</script>

<style lang="scss" scoped>
section {
  padding: 10vh 0;
  @media screen and (min-width: 1101px) {
    background-image: url('@/assets/top/back_gazo001.webp');
    background-position: center center; /* 背景画像を中央に配置 */
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 9vh 0;
    background-color: var(--sub2);
  }
  @media screen and (max-width: 767px) {
    padding: 11vh 0;
    background-color: var(--sub2);
  }
  .wrap {
    position: relative;
    .content {
      width: 50%;
      padding: 0;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h2 {
        line-height: 50px;
        white-space: nowrap;
        margin-top: 0;
        @media screen and (max-width: 767px) {
          line-height: 30px;
          text-align: center;
          margin: 0;
        }
      }
      .movie {
        display: block;
        margin-top: 40px;
        @media screen and (max-width: 767px) {
          display: block;
          margin: 0;
        }
        .movie-sp-img {
          display: none;
          @media screen and (max-width: 767px) {
            display: block;
            width: 70%;
            margin: 30px auto;
          }
        }
        .movie-text {
          width: 50%;
          font-size: 22px;
          font-weight: 500;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 100%;
          }
          @media screen and (max-width: 767px) {
            text-align: center;
            width: 100%;
          }
        }
        .movie-link {
          display: block;
          width: 50%;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          padding: 15px 0;
          margin-top: 20px;
          border: 1px solid var(--font);
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 100%;
            padding: 10px 0;
            margin-top: 20px;
          }
          @media screen and (max-width: 767px) {
            width: 90%;
            padding: 10px 0;
            margin: 15px auto 0 auto;
          }
          &:hover {
            color: var(--white);
            background-color: var(--sub1);
            border-color: var(--sub1);
          }
        }
      }
    }
    .movie-img {
      width: 30%;
      aspect-ratio: 1 / 1;
      position: absolute;
      top: -40%;
      right: 0;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: auto;
        height: 100%;
        top: 0;
        right: 10%;
      }
    }
  }
}
</style>
