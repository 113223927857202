<template>
  <section id="use-case">
    <div class="wrap">
      <h2>よくある活用事例</h2>
      <div class="case-icon">
        <div class="case">
          <img src="@/assets/top/icon008.webp" alt="事例1:集客">
          <!-- <p>集客</p> -->
        </div>
        <div class="case">
          <img src="@/assets/top/icon009.webp" alt="事例2:広告">
          <!-- <p>広告</p> -->
        </div>
        <div class="case">
          <img src="@/assets/top/icon010.webp" alt="事例3:商品">
          <!-- <p>商品</p> -->
        </div>
        <div class="case">
          <img src="@/assets/top/icon011.webp" alt="事例4:求人">
          <!-- <p>求人</p> -->
        </div>
      </div>
      <div class="use-case">
        <div class="case-content">
          <h3 class="case-title">
            活用事例
            <span>01</span>
            集客
          </h3>
          <img class="user-sp-img" src="@/assets/top/gazo002.webp" alt="神田様">
          <div class="user-profile">
            <div class="profile long">
              <p class="user-business">ヘアーサロン</p>
              <p class="user-name">マネージャー</p>
            </div>
            <div class="user-case short">
              <img src="@/assets/top/icon008.webp" alt="事例1:集客">
            </div>
          </div>
          <p class="user-message">
            ミナティを導入してから、当salonの集客に変化がありました。特に若い世代からの反応が良く、新規顧客の獲得に繋がっています。サービスや特典を知り、興味を持って来店してくれます！この様な集客ツールを待っていました！
          </p>
        </div>
        <div class="user-img pc-tablet-only">
          <img src="@/assets/top/gazo002.webp" alt="ヘアーサロン マネージャー">
        </div>
      </div>
      <div class="use-case">
        <div class="user-img pc-tablet-only">
          <img src="@/assets/top/gazo003.webp" alt="エステサロン オーナー">
        </div>
        <div class="case-content">
          <h3 class="case-title">
            活用事例
            <span>02</span>
            集客・商品販売
          </h3>
          <img class="user-sp-img" src="@/assets/top/gazo003.webp" alt="エステサロン オーナー">
          <div class="user-profile">
            <div class="profile">
              <p class="user-business">エステサロン</p>
              <p class="user-name">オーナー</p>
            </div>
            <div class="user-case">
              <img class="first-icon" src="@/assets/top/icon008.webp" alt="事例1:集客">
              <img src="@/assets/top/icon010.webp" alt="事例3:商品">
            </div>
          </div>
          <p class="user-message">
            ミナティを利用することでお客様とのコミュニケーションもスムーズになり、リピーターの増加に貢献できています。SNS広告は現代のビジネスにおいては欠かせないツールであり、今後も積極的に活用していきたいと思います！
          </p>
        </div>
      </div>
      <div class="use-case">
        <div class="case-content">
          <h3 class="case-title">
            活用事例
            <span>03</span>
            集客・広告
          </h3>
          <img class="user-sp-img" src="@/assets/top/gazo004.webp" alt="鳥焼肉専門店 オーナー">
          <div class="user-profile">
            <div class="profile">
              <p class="user-business">鳥焼肉専門店</p>
              <p class="user-name">オーナー</p>
            </div>
            <div class="user-case">
              <img class="first-icon" src="@/assets/top/icon008.webp" alt="事例1:集客">
              <img src="@/assets/top/icon009.webp" alt="事例2:広告">
            </div>
          </div>
          <p class="user-message">
            ミナティを利用したSNS広告は効果的で、多くの新規顧客を引き付けることが出来ました。写真や動画を使って料理や雰囲気を伝え、興味をもってくれるお客様が増えました！特典や告知もスムーズに発信できるのがとても便利です！
          </p>
        </div>
        <div class="user-img pc-tablet-only">
          <img src="@/assets/top/gazo004.webp" alt="鳥焼肉専門店 オーナー">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UseCases"
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--white);
  .wrap {
    @media screen and (max-width: 767px) {
      padding: 0;
    }
    h2 {
      text-align: center;
      margin: 0;
    }
    .case-icon {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 90%;
        padding-left: 20px;
        padding-right: 20px;
        margin-right: auto;
        margin-left: auto;
      }
      @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
        justify-content: space-around;
      }
      .case {
        width: 23%;
        margin-top: 60px;
        @media screen and (max-width: 767px) {
          width: 45%;
          margin-top: 30px;
        }
        img {
          aspect-ratio: 1 / 1;
        }
        p {
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          margin-top: 20px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            font-size: 22px;
          }
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
    }
    .use-case {
      display: flex;
      justify-content: space-between;
      padding: 40px 60px;
      margin-top: 60px;
      background-color: var(--sub2);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        padding: 20px 40px;
        margin-top: 40px;
      }
      @media screen and (max-width: 767px) {
        display: block;
        padding: 0;
        padding-bottom: 5vh;
        background-color: var(--sub2);
      }
      .case-content {
        width: 60%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .case-title {
          font-size: 24px;
          line-height: 45px;
          padding: 0 20px;
          margin: 0;
          color: var(--white);
          background-color: var(--sub1);
          border: 2px solid var(--sub1);
          @media screen and (max-width: 767px) {
            font-size: 20px;
            padding: 0 20px;
          }
          span {
            display: inline-block;
            height: 100%;
            padding: 0 10px;
            margin: 0 10px;
            color: var(--sub1);
            background-color: var(--white);
            @media screen and (max-width: 767px) {
              padding: 0 5px;
              margin: 0 5px;
            }
          }
        }
        .user-profile {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          @media screen and (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
          }
          .profile {
            width: 50%;
            margin-top: auto;
            .user-business {
              width: 100%;
              font-weight: 500;
            }
            .user-name {
              width: 100%;
              font-size: 20px;
              font-weight: 500;
              @media screen and (max-width: 767px) {
                font-size: 18px;
              }
            }
          }
          .user-case {
            width: 50%;
            display: flex;
            justify-content: end;
            img {
              width: 40%;
              aspect-ratio: 1 / 1;
            }
            .first-icon {
              margin-right: 10px;
            }
          }
          .long {
            width: 70%;
          }
          .short {
            width: 20%;
            img {
              width: 100%;
            }
          }
        }
        .user-message {
          margin-top: 20px;
          @media screen and (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
      .user-img {
        width: 30%;
        img {
          margin-bottom: auto;
          aspect-ratio: 1 / 1;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
      .user-sp-img {
        display: none;
        width: 70%;
        margin: 20px auto;
        aspect-ratio: 1 / 1;
        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }
  }
}
</style>