<template>
  <div class="">
    <div class="header"></div>
    <Top title="ミナティ加盟店規約" />
    <section>
      <div class="wrap">
        <h3>第１条（総則）</h3>
        <ol>
          <li>本規約は、株式会社カイプラネット（以下「当社」といいます。）が運営するウェブサイト（名称：ミナティ、以下「本サイト」といいます。）上に広告を掲載する等のサービス（以下「本サービス」といいます。）について、本サービスを利用する上で遵守すべき事項等を定めます。</li>
          <li>本サービスをご利用いただくには、本規約の全ての条項をご承諾いただく必要があります。なお、本サービスをご利用いただいた場合には、本規約の全ての条項をご承諾いただいたものとみなします。</li>
        </ol>

        <h3>第２条（適用）</h3>
        <ol>
          <li>本規約は、本サービスの提供条件および本サービスの利用に関する当社と本サービスの利用契約（以下「本契約」といいます。）の契約者との間の権利義務関係を定めることを目的とし、当社と契約者との間の本サービスの利用に関わる一切の関係に適用されます。</li>
          <li>当社が本サイトに別途掲載する本サービスの利用に関するルールは、本規約の一部を構成するものとし、契約者は、これらを全て遵守し、本サービスを利用するものとします。</li>
          <li>本規約の内容と前項のルールその他本規約外における本サービスの説明等が異なる場合には、当該説明等の規定を優先させる旨の特段の定めがない限り、本規約の規定が優先して適用されます。</li>
        </ol>

        <h3>第３条（加盟店登録）</h3>
        <ol>
          <li>本サービスは、本条に従って本サイトの加盟店登録を行った者（以下「加盟店」といいます。）のみが利用できます。</li>
          <li>本サービスの利用を希望する者は、本規約を遵守することに同意し、かつ当社所定の情報（以下「登録事項」といいます。）を当社所定の方法で当社に提供することにより、当社に対し、加盟店登録の申請をします。</li>
          <li>当社は、当社所定の基準に従って、前項に基づいて登録申請を行った者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合には、その旨を登録申請者に通知します。登録申請者の加盟店登録は、登録申請者に本項の通知が到達したことをもって完了したものとします。</li>
          <li>当社が加盟店登録を認めるのは、登録申請者が事業者の場合に限ります。</li>
          <li>
            当社は、登録申請者が以下の各号のいずれかに該当する場合には、加盟店登録を拒否することができ、また、その理由について一切開示義務を負いません。
            <span class="indent">⑴ 当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合</span>
            <span class="indent">⑵ 重複して登録申請をした場合</span>
            <span class="indent">⑶ 過去に当社との契約に違反した者またはその関係者であると当社が判断した場合</span>
            <span class="indent">⑷ 過去に第６条に定める措置を受けたことがある場合</span>
            <span class="indent">⑸ その他、当社が加盟店登録を適当でないと判断した場合</span>
          </li>
        </ol>

        <h3>第４条（登録事項の変更）</h3>
        <ol>
          <li>加盟店は、登録事項に変更があった場合には、当社所定の方法により、遅滞なく変更内容を当社に届け出なければなりません。</li>
          <li>当社は、前項の届出がなされなかったことにより生じた損害について、一切の責任を負いません。</li>
        </ol>

        <h3>第５条（ログインＩＤおよびパスワードの管理）</h3>
        <ol>
          <li>当社は、加盟店登録時に、登録申請者に対し、ログインＩＤおよびパスワードを付与します。</li>
          <li>加盟店は、自己の責任において、ログインＩＤおよびパスワードを適切に管理および保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはなりません。</li>
          <li>ログインＩＤまたはパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は加盟店が負うものとし、当社は、一切の責任を負いません。</li>
          <li>ログインＩＤおよびパスワードを用いてなされた本サービスの利用は、全て当該ログインＩＤおよびパスワードを付与された加盟店によりなされたものとみなし、当該ログインＩＤおよびパスワードの不正利用により当社、本サイトの会員または第三者が損害を被った場合には、当社は、一切の責任を負わず、当該加盟店が、当該損害を賠償しなければなりません。</li>
        </ol>

        <h3>第６条（当社による加盟店登録の抹消）</h3>
        <ol>
          <li>
            当社は、加盟店が以下の各号のいずれかに該当する場合には、事前に通知または催告することなく、当該加盟店について本サービスの利用を一時的に停止し、または加盟店登録を抹消することができます。
            <span class="indent">⑴ 本規約のいずれかの条項に違反した場合</span>
            <span class="indent">⑵ 支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合</span>
            <span class="indent">⑶ 当社からの問い合わせその他回答を求める連絡に対して３０日間以上応答がない場合</span>
            <span class="indent">⑷ 第３条第５項各号に該当する場合</span>
            <span class="indent">⑸ その他、当社が加盟店登録を適当でないと判断した場合</span>
          </li>
          <li>加盟店は、前項各号のいずれかの事由に該当した場合には、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行わなければなりません。</li>
          <li>当社は、本条に基づき当社が行った措置により加盟店に生じた損害について、一切の責任を負いません。</li>
        </ol>

        <h3>第７条（加盟店による加盟店登録の抹消）</h3>
        <ol>
          <li>加盟店は、当社所定の手続きを行うことにより、いつでも加盟店登録を抹消することができます。</li>
          <li>加盟店は、加盟店登録の抹消にあたり、当社に対して負っている債務がある場合には、当該債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行わなければなりません。</li>
          <li>加盟店登録の抹消後の登録事項の取扱いについては、次条の規定に従うものとします。</li>
        </ol>

        <h3>第８条（登録事項等の取扱い）</h3>
        <ol>
          <li>登録事項および本契約に基づき知り得た加盟店に関する情報（本契約内容を含みます。）の取扱いについては、加盟店用プライバシーポリシーの定めによるものとし、加盟店は、これに従って当社がこれらを取扱うことについて同意します。</li>
          <li>当社は、加盟店が当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用および公開することができるものとし、加盟店は、これに同意します。</li>
        </ol>

        <h3>第９条（本サービスの利用申込み）</h3>
        <p>本サービスの利用を希望する加盟店は、当社所定の方法により必要事項を入力し、本サービスの利用申込みをします。</p>

        <h3>第１０条（契約の締結）</h3>
        <ol>
          <li>当社は、前条の申込みに対して、当社所定の基準により、本サービスの利用の可否を判断し、承諾する場合には、その旨を申込者に通知します。承諾の通知が申込者に到達したことをもって、本契約が締結されたものとします。</li>
          <li>
            当社は、以下の各号のいずれかに該当する場合には、申込みを承諾しないものとし、また、その理由について一切開示義務を負いません。
            <span class="indent">⑴ 前条の入力内容の全部または一部につき虚偽、誤記または記載漏れがあった場合</span>
            <span class="indent">⑵ 申込みにかかる広告内容またはリンク先のウェブサイトが不適切であると当社が判断した場合</span>
            <span class="indent">⑶ その他当社が承諾を適当でないと判断した場合</span>
          </li>
        </ol>

        <h3>第１１条（入力内容の変更）</h3>
        <ol>
          <li>本契約を締結した加盟店（以下「契約者」といいます。）は、第９条の入力内容に変更があった場合には、当社所定の方法により、遅滞なく変更内容を当社に届け出なければなりません。</li>
          <li>当社は、前項の届出がなされなかったことにより生じた損害について、一切の責任を負いません。</li>
        </ol>

        <h3>第１２条（広告の掲載）</h3>
        <ol>
          <li>当社は、本契約が締結された場合、当社所定の期日までに、契約者の広告を本サイトに掲載します。</li>
          <li>当社が認める場合を除き、契約者が掲載できる広告は、１契約につき、１店舗のみとします。契約者は、複数の店舗の広告掲載を希望する場合には、店舗ごとに契約を締結しなければなりません。</li>
          <li>契約者は、当社に通知することにより、通知日の属する月の翌月分以降の契約プランを変更することができます。</li>
        </ol>

        <h3>第１３条（会員に対する特典の付与）</h3>
        <p>契約者は、当社所定の条件表（以下「本条件表」といいます。）に従い、本サイトの会員のうち、契約者の顧客に対し、特典を付与することができます。</p>

        <h3>第１４条（顧客管理システムおよびポイントシステムの利用）</h3>
        <ol>
          <li>加盟店は、当社が提供する顧客管理システムを利用することができます。</li>
          <li>契約者は、前項の顧客管理システムおよび当社が提供するポイントシステムを利用することができます。</li>
        </ol>

        <h3>第１５条（有効期間）</h3>
        <p>
          本契約の有効期間は広告掲載開始日から６か月または１２か月とし、契約者がいずれかを選択できるものとします。ただし、期間満了の１か月前までに、当社または契約者から特段の申し出がない場合には、本契約と同一条件でさらに同一期間継続し、以後も同様とします。
        </p>

        <h3>第１６条（サービス利用料）</h3>
        <ol>
          <li>本サービス利用料（以下「本利用料」といいます。）は、本条件表に基づき算出します。なお、月中に本サービスの提供を開始または終了する場合であっても、日割計算は行わないものとします。</li>
          <li>契約者は、当社に対し、当月分の本利用料を、当月末日までに、当社所定の方法により支払います。なお、振込手数料は契約者の負担とします。</li>
          <li>契約者は、本利用料の支払いを遅滞した場合には、当社に対し、支払期日の翌日より完済の日まで年１４．６％の割合による遅延損害金を支払います。</li>
          <li>契約者は、第１２条第２項に違反した場合において当社が請求したときは、当社に対し、違約金として５０万円を直ちに支払います。</li>
          <li>当社は、当社に債務不履行があった場合および当社の都合により本サービスの提供を終了する場合を除き、いかなる事情が生じたとしても、契約者に対し、本利用料の返還または支払の免除を行いません。</li>
          <li>当社は、当社所定の方法で１か月前に通知することにより、本条件表の内容を自由に変更することができます。</li>
        </ol>

        <h3>第１７条（禁止事項）</h3>
        <p>
          契約者は、本サービスを利用するにあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
          <span class="indent">⑴ 法令に違反する行為または犯罪行為に関連する行為</span>
          <span class="indent">⑵ 当社、本サイトの会員、本サービスの他の利用者またはその他の第三者に対する詐欺または強迫行為</span>
          <span class="indent">⑶ 公序良俗に反する行為</span>
          <span class="indent">⑷ 当社、本サイトの会員、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為</span>
          <span class="indent">
            ⑸ 本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社、本サイトの会員、本サービスの他の利用者またはその他の第三者に送信し、または閲覧させる行為
            <span class="indent">① 過度に暴力的または残虐な表現を含む情報</span>
            <span class="indent">② コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報</span>
            <span class="indent">③ 当社、本サイトの会員、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報</span>
            <span class="indent">④ 過度にわいせつな表現を含む情報</span>
            <span class="indent">⑤ 差別を助長する表現を含む情報</span>
            <span class="indent">⑥ 契約者の事業と関係のない情報</span>
          </span>
          <span class="indent">⑹ 本サービスのネットワークまたはシステム等に過度な負荷をかける行為</span>
          <span class="indent">⑺ 当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為</span>
          <span class="indent">⑻ 第三者に成りすます行為</span>
          <span class="indent">⑼ 当社、本サイトの会員、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為</span>
          <span class="indent">⑽ 本規約に抵触する行為</span>
          <span class="indent">⑾ 前各号の行為を直接または間接に惹起し、または容易にする行為</span>
          <span class="indent">⑿ その他当社が不適切と判断する行為</span>
        </p>

        <h3>第１８条（守秘義務）</h3>
        <ol>
          <li>契約者は、本契約に基づき知り得た当社の営業上および技術上の秘密情報、その他秘密情報である旨が明示された情報（以下「秘密情報」といいます。）について厳に秘密を保持し、第三者に開示または漏洩し、本契約以外のいかなる目的のためにも使用または複製してはなりません。</li>
          <li>
            前項の規定は、次に掲げる情報については、適用しないものとします。
            <span class="indent">⑴ 開示を受ける際に、既に自ら所有していた情報</span>
            <span class="indent">⑵ 開示を受ける際に、既に公知であった情報</span>
            <span class="indent">⑶ 開示を受けた後、自己の責によらずに公知となった情報</span>
            <span class="indent">⑷ 正当な権限を有する第三者から適法に取得した情報</span>
            <span class="indent">⑸ 開示された情報によることなく自らが独自に開発、取得した情報</span>
          </li>
          <li>契約者は、第１項の規定にかかわらず、自己もしくは親会社の役職員または弁護士、公認会計士その他法律に基づき守秘義務を負う者に対して秘密情報を開示することが必要であると合理的に判断される場合には、同等の義務を負わせることを条件に、契約者の責任において必要最小限の範囲に限って秘密情報をそれらの者に対し開示することができます。</li>
          <li>契約者は、第１項の規定にかかわらず、法令または裁判所、政府機関、金融商品取引所その他契約者に対して権限を有する機関の裁判、命令、規則等により秘密情報の開示を要求された場合には、合理的に必要な範囲で秘密情報を開示することができます。</li>
        </ol>

        <h3>第１９条（反社会的勢力の排除）</h3>
        <p>
          契約者は、本サービスを利用するにあたり、以下に定める事項を確約しなければなりません。
          <span class="indent">
            ⑴ 契約者は、自己、自社もしくはその役職員が暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団、その他集団的にまたは常習的に違法行為を行うことを助長するおそれがある団体もしくはかかる団体の構成員またはこれらに準ずると判断される者（以下「反社会的勢力等」といいます。）に該当せず、かつ将来にわたっても該当しないこと。
          </span>
          <span class="indent">
            ⑵ 契約者は、反社会的勢力等と次のいずれの関係にも該当せず、かつ将来にわたっても該当しないこと。
            <span class="indent">① 反社会的勢力等が自己の事業または自社の経営を支配していると認められること</span>
            <span class="indent">② 反社会的勢力等が自己の事業または自社の経営に実質的に関与していると認められること</span>
            <span class="indent">③ 自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもって反社会的勢力等を利用したと認められること</span>
            <span class="indent">④ 反社会的勢力等に資金等を提供し、または便宜を供与するなどの関与をしていると認められること</span>
            <span class="indent">⑤ その他反社会的勢力等と役員または経営に実質的に関与している者が、社会的に非難されるべき関係にあると認められること</span>
          </span>
        </p>

        <h3>第２０条（本サービスの停止等）</h3>
        <ol>
          <li>
            当社は、以下の各号のいずれかに該当する場合には、事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができます。
            <span class="indent">⑴ 本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</span>
            <span class="indent">⑵ コンピューター、通信回線等が事故により停止した場合</span>
            <span class="indent">⑶ 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</span>
            <span class="indent">⑷ その他当社が停止または中断を必要と判断した場合</span>
          </li>
          <li>当社は、前項に基づき当社が行った措置により契約者に生じた損害について、一切の責任を負いません。</li>
        </ol>

        <h3>第２１条（本サービス内容の変更等）</h3>
        <ol>
          <li>当社は、当社の都合により、事前に通知することなく、本サービスの内容を変更し、または提供を終了することができます。</li>
          <li>本サービスの提供を終了する場合、本サービスの提供終了日をもって本契約も終了します。</li>
          <li>当社は、第１項に基づき当社が行った措置により契約者に生じた損害について、一切の責任を負いません。</li>
        </ol>

        <h3>第２２条（本契約の解約）</h3>
        <ol>
          <li>契約者は、２週間以上の予告期間をもって当社に書面にて通知することにより、いつでも本契約を中途解約することができます。</li>
          <li>契約者は、前項による中途解約をした場合であっても、残存期間分の本利用料の支払義務を負います。ただし、本利用料の変更など本契約の重要な条件に変更があった場合において、契約者が当該変更の通知を受けた日から１か月以内に、当該変更を理由として中途解約を申し出た場合に限り、契約者は、当該支払義務を負わないものとします。</li>
        </ol>

        <h3>第２３条（本契約の解除等）</h3>
        <ol>
          <li>
            当社は、契約者について以下の各号のいずれかに該当する場合には、何らの催告を要せず、直ちに契約者に対する本サービスの提供を停止または中断し、または本契約を解除することができます。
            <span class="indent">⑴ 本契約に違反し、またはそのおそれがあると当社が判断した場合</span>
            <span class="indent">⑵ 第１０条第２項の不承諾事由に該当することが判明した場合</span>
            <span class="indent">⑶ 監督官庁より営業の取消し、停止等の処分を受けた場合</span>
            <span class="indent">⑷ 支払停止もしくは支払不能の状態に陥ったとき、または手形交換所から警告もしくは不渡り処分を受けた場合</span>
            <span class="indent">⑸ 信用資力の著しい低下があったとき、またはこれに影響を及ぼす営業上の重要な変更があったとき</span>
            <span class="indent">⑹ 差押え、仮差押え、仮処分、その他強制執行もしくは競売の申立て、または公租公課の滞納処分を受けた場合</span>
            <span class="indent">⑺ 破産手続開始、民事再生手続開始、会社更生手続開始もしくは特別清算開始の申立てを受け、または自ら申し立てをした場合</span>
            <span class="indent">⑻ 解散の決議をし、または他の会社と合併した場合</span>
            <span class="indent">⑼ 前各号に類する事由が生じ、本契約の継続が困難であると当社が合理的に判断した場合</span>
            <span class="indent">⑽ その他本契約を継続し難い背信行為があった場合</span>
          </li>
          <li>当社は、前項に基づき当社が行った措置により契約者に生じた損害について、一切の責任を負いません。</li>
        </ol>

        <h3>第２４条（保証の否認）</h3>
        <p>
          当社は、本サービスにつき、以下の各号に規定する事項を保証するものではありません。
          <span class="indent">⑴ 契約者の特定の目的に適合すること</span>
          <span class="indent">⑵ 契約者の期待する機能、価値、正確性、確実性および有用性を有すること</span>
          <span class="indent">⑶ 契約者に適用される法令、業界団体の内部規則およびＳＮＳ運営者等が定める規則等に適合すること</span>
          <span class="indent">⑷ 掲載した広告の閲覧数および評価ならびに契約者の売上の上昇等本サービスを利用したことによる成果</span>
        </p>

        <h3>第２５条（免責）</h3>
        <ol>
          <li>当社は、本サービスの提供の中断、停止、終了、利用不能または変更等により契約者が損害を被った場合であっても、一切の責任を負いません。</li>
          <li>当社は、本サイトおよび本サービスに関連して契約者と第三者との間において生じた取引、連絡、紛争等について、一切の責任を負いません。</li>
          <li>当社は、天災地変、暴動、戦争、テロ行為、動乱、革命、法令の制定改廃、交通事故、公権力の行使に基づく処分、輸送機関の事故、労働争議、その他当社の合理的な支配が及ばない事由により、本サービスの提供の遅滞または不能を生じた場合には、一切の責任を負いません。</li>
          <li>当社が本サービス上の義務に違反しこれにより契約者に損害が生じた場合であって、その義務違反につき当社に故意または重大な過失がある場合に限り、当社は、契約者に対し、損害賠償義務を負うものとします。</li>
          <li>当社が損害賠償義務を負う場合であっても、当社は、契約者に生じた損害のうち、直接損害に限り、契約者が当社に支払った本利用料の累積金額を上限として損害を賠償します。</li>
        </ol>

        <h3>第２６条（契約者の責任）</h3>
        <ol>
          <li>契約者は、本契約上の義務に違反し当社に損害を生じさせた場合には、当社に対し、全ての損害を賠償するものとし、その請求回収のために当社が弁護士に委任したときは、合理的な弁護士費用も負担します。</li>
          <li>契約者は、本サービスの利用により契約者が第三者の権利を侵害した結果、当社が当該第三者から請求または要求を受けた場合、当社に対し、当社が被った損害を全て補償します。当社は、当該第三者に対し損害賠償金を支払う前であっても、契約者に対し、当該損害にかかる補償義務の履行を請求できます。</li>
          <li>契約者は、本サービスの利用により、第三者との間に紛争が生じ、または自ら損害を被り、もしくは他人に損害を生じさせた場合には、全て契約者の責任において解決するものとします。</li>
        </ol>

        <h3>第２７条（本規約等の変更）</h3>
        <ol>
          <li>当社は、契約者の事前の承諾を得ることなく、１か月前に通知することにより、本規約の全部または一部を変更することができます。</li>
          <li>当社は、前項による変更を行う場合には、契約者に対し、当社所定の方法により当該変更内容を通知します。</li>
          <li>契約者は、前項による通知を受けた日から１か月以内に本契約の解約手続をとらなかった場合には、本規約の変更に同意したものとします。</li>
        </ol>

        <h3>第２８条（譲渡等の禁止）</h3>
        <p>契約者は、当社の書面による事前の承諾を得ることなく、本契約上の地位または本契約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をしてはなりません。</p>

        <h3>第２９条（分離可能性）</h3>
        <p>本規約のいずれかの条項またはその一部が、民法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>

        <h3>第３０条（存続条項）</h3>
        <p>本契約が終了した場合であっても、第４条第２項、第５条第２項、第６条第２項および第３項、第７条第２項および第３項、第１１条第２項、第１６条第１項ないし第６項、第１８条、第２０条第２項、第２１条第３項、第２２条第２項、第２３条第２項、第２４条、第２５条、第２６条、第２８条、第２９条、第３１条および第３２条の規定はなお効力を有します。</p>

        <h3>第３１条（誠実協議義務）</h3>
        <p>当社および契約者は、本規約に定めのない事項または本規約の解釈に疑義を生じた場合には、協議の上、誠意をもってその解決にあたります。</p>

        <h3>第３２条（準拠法および管轄裁判所）</h3>
        <ol>
          <li>本規約の準拠法は日本法とします。</li>
          <li>本サービスまたは本規約に起因し、または関連する一切の紛争については、岐阜地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
        </ol>

        <p class="end">以上</p>
        <p class="terms">【２０２４年６月１７日制定】</p>
      </div>
    </section>
    <TopButtonVue class="top" />
  </div>
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import TopButtonVue from '@/components/Ui/TopButton.vue';

export default {
  name: "TermsVue",
  components: {
    Top,
    TopButtonVue,
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
section {
  .wrap {
    .desc-sub-title {
      font-size: 16px;
      font-weight: 300;
      color: var(--font);
      line-height: 1.5rem;
      text-indent: 1rem;
    }
    h3 {
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    ol {
      @media screen and (max-width: 767px) {
        padding-inline-start: 25px;
      }
      li {
        line-height: 1.5rem;
        margin-bottom: 5px;
      }
    }
    p {
      text-indent: 1rem;
    }
    .indent {
      display: block;
      margin-left: 1rem;
    }
    .end {
      text-indent: 0;
      text-align: end;
      margin-top: 20px;
    }
    .terms {
      text-indent: 0;
      margin-top: 20px;
    }
  }
}
.top {
  margin: 20px auto;
}
</style>