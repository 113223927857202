<template>
  <section>
    <div class="wrap">
      <div class="ad-message">
        <h2>
          MINATYへ
          <br>
          広告掲載しませんか
        </h2>
        <p>
          ミナティでは、ウェブサイトにおける企業広告を募集しております。
        </p>
      </div>
      <img src="@/assets/advertisements/gozo001.webp" alt="Webサイトにおける企業広告を募集中です。" class="pc-tablet-only">
      <img src="@/assets/advertisements/sp_gozo001.webp" alt="Webサイトにおける企業広告を募集中です。" class="sp-only">
    </div>
  </section>
</template>

<script>
export default {
  name: "AdvertiseWithMinaty"
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .ad-message {
      width: 45%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h2 {
        margin-top: 0;
        line-height: 50px;
        @media screen and (max-width: 767px) {
          line-height: 30px;
        }
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
    img {
      width: 50%;
      @media screen and (min-width: 768px) {
        aspect-ratio: 11 / 4;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
</style>