<template>
  <div class="header"></div>
  <Top title="お問い合わせ" />
  <ContactVue />
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import ContactVue from '@/components/top/Contact.vue';

export default {
  name: 'ContactView',
  components: {
    Top,
    ContactVue,
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
</style>
