<template>
  <section>
    <div class="wrap">
      <div class="solicitation">
        <div class="solicitation-message">
          <h2>
            MINATYシステム
            <br class="sp-only">
            販売パートナーとして
            <br>
            活躍しませんか？！
          </h2>
          <p>
            ミナティでは、全国の中小企業・個人事業主さまとMINATYを繋ぐマッチングパートナーさま、システム販売パートナーさまを募集しています。私たちと共にSNSビジネスを構築しましょう。
          </p>
          <img src="@/assets/top/rogo.webp" alt="MINATY ロゴ" class="pc-tablet-only">
        </div>
        <img src="@/assets/partner/gazo001.webp" alt="特許技術で安心サポート！" class="solicitation-img pc-tablet-only">
        <img src="@/assets/partner/sp_gazo001.webp" alt="特許技術で安心サポート！" class="solicitation-img sp-only">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SolicitationVue",
}
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 0;
  .solicitation {
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      min-height: 320px;
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
    .solicitation-message {
      width: 60%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h2 {
        line-height: 50px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 24px;
          line-height: 40px;
        }
        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 35px;
        }
      }
      img {
        width: 40%;
        margin-top: 10px;
      }
    }
    .solicitation-img {
      width: 40%;
      aspect-ratio: 135 / 119;
      margin-top: auto;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>