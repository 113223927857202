<template>
  <section>
    <div class="wrap">
      <h2>選ばれる理由</h2>
      <div class="reasons">
        <div class="reason">
          <img src="@/assets/top/icon004.webp" alt="SNSの集客に特化！">
          <p>SNS集客に特化</p>
        </div>
        <div class="reason">
          <img src="@/assets/top/icon005.webp" alt="コストパフォーマンスが良い！">
          <p>コスト<br class="sp-only">パフォーマンス</p>
        </div>
        <div class="reason">
          <img src="@/assets/top/icon006.webp" alt="他社システムと融合！">
          <p>他社システム<br class="sp-only">との融合</p>
        </div>
        <div class="reason">
          <img src="@/assets/top/icon007.webp" alt="全ての業態に対応しています！">
          <p>全ての業態に対応</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReasonVue",
}
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 80px;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 6vh;
  }
  .wrap {
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 75%;
    }
    h2 {
      text-align: center;
      margin-top: 0;
    }
    .reasons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 1100px) {
        justify-content: space-around;
      }
      .reason {
        width: 23%;
        margin-top: 60px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 40%;
          margin-top: 30px;
        }
        @media screen and (max-width: 767px) {
          width: 45%;
          margin-top: 30px;
        }
        p {
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          margin-top: 20px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            font-size: 22px;
          }
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>