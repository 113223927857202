<template>
  <section>
    <div class="wrap">
      <h2>安心のサポート体制</h2>
      <div class="support-system">
        <div class="support">
          <h3>研修</h3>
          <img src="@/assets/partner/icon002.webp" alt="企業訪問、オンライン講座の実施でサポートします。">
          <h4>企業訪問または<br>オンライン講座の実施</h4>
          <p>
            営業の進め方に関するレクチャー講座を実施いたします。
          </p>
        </div>
        <div class="support">
          <h3>資料</h3>
          <img src="@/assets/partner/icon003.webp" alt="パートナー様専用の乙録QRコード付きのパンフレットをご提供いたします。">
          <h4>登録QRコード付き<br>パンフレットのご提供</h4>
          <p>
            オーナーさまの登録を簡単にサポート。パートナーさま専用の登録QRコード付きのパンフレットをご提供。
          </p>
        </div>
        <div class="support">
          <h3>動画</h3>
          <img src="@/assets/partner/icon004.webp" alt="説明が苦手な方にも安心していただけるよう、説明動画をご用意しております。">
          <h4>説明不要の<br>詳細動画を視聴</h4>
          <p>
            説明が苦手な方にも安心。説明動画を視聴していただくだけなので、誰でも営業ができます。
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SupportSystem"
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  .wrap {
    h2 {
      text-align: center;
      margin-top: 0;
      @media screen and (max-width: 767px) {
        font-size: 23px;
      }
    }
    .support-system {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .support {
        width: 32%;
        padding: 30px 20px;
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 30px;
          margin: 20px 0;
        }
        background-color: var(--white);
        h3 {
          text-align: center;
          border-radius: 999px;
          padding: 10px 0;
          margin: 0;
          color: var(--white);
          background-color: var(--main);
        }
        img {
          display: block;
          width: 80%;
          margin: 30px auto;
          @media screen and (max-width: 767px) {
            width: 75%;
            margin: 20px auto;
          }
        }
        h4 {
          text-align: center;
          color: var(--main);
          font-size: 22px;
          line-height: 32px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            font-size: 18px;
            line-height: 28px;
            margin: 20px 0;
          }
          @media screen and (max-width: 767px) {
            font-size: 20px;
            line-height: 30px;
            margin: 15px 0;
          }
        }
      }
    }
  }
}
</style>