<template>
  <section>
    <div class="wrap what-partner">
      <div class="partner-content">
        <h2>パートナー制度とは</h2>
        <p>
          オーナーさまにミナティをご紹介いただき、ミナティ各種サービスをご契約いただくことで、契約内容に応じたインセンティブを受け取れる制度です。
        </p>
        <div class="appeal">
          <div class="appeal-message">登録費用無料</div>
          <div class="appeal-message">どなたでも登録OK</div>
        </div>
      </div>
      <div class="what-partner-img">
        <img class="pc-tablet-only" src="@/assets/partner/icon001.webp" alt="パートナー制度説明図">
        <img class="sp-only" src="@/assets/partner/sp_icon001.webp" alt="パートナー制度説明図">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhatPartner",
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--white);
  .what-partner {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .partner-content {
      width: 55%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h2 {
        color: var(--main);
        margin-top: 0;
        @media screen and (max-width: 767px) {
          text-align: center;
        }
      }
      p {
        margin-bottom: 40px;
        @media screen and (max-width: 1100px) {
          margin-bottom: 0;
        }
      }
      .appeal {
        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: space-between;
        }
        .appeal-message {
          width:  48%;
          text-align: center;
          font-weight: 500;
          border-radius: 999px;
          padding: 10px 0;
          margin: 20px 0;
          color: var(--white);
          background-color: var(--main);
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            margin: 15px 0;
          }
        }
      }
    }
    .what-partner-img {
      width: 40%;
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
</style>