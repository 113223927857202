<template>
  <div class="wrap">
    <a class="description-link" v-scroll-to="'#contact'" v-show="this.$route.path === '/'">カスタマーサポート</a>
    <router-link class="description-link" to="/privacy">プライバシーポリシーについて</router-link>
    <router-link class="description-link" to="/terms" v-show="this.$route.path !== '/hp-partner/'">利用規約について</router-link>
    <router-link class="description-link" to="/hp-partner/terms" v-show="this.$route.path === '/hp-partner/'">ミナティパートナー規約</router-link>
    <router-link class="description-link" to="/hp-partner/faq" v-show="this.$route.path === '/hp-partner/'">よくある質問</router-link>
  </div>
</template>

<script>
export default {
  name: "DescriptionLink"
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  justify-content: space-around;
  margin: 40px auto;
  @media screen and (max-width: 767px) {
    display: block;
  }
  a {
    display: block;
    width: 30%;
    font-size: 18px;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1.5px solid var(--font);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 32%;
      font-size: 16px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
    }
    &:hover {
      color: var(--main);
      border-bottom: 1.5px solid var(--main);
    }
  }
}
</style>