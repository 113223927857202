import store from "@/store";
import { createRouter, createWebHistory } from 'vue-router';
import { setTitle, setDescription } from "@/mixins/index.js";
import HomeView from '../views/HomeView.vue';
import Partner from '../views/Partner.vue';
import Info from '../views/Info.vue';
import TermsVue from '../views/Terms.vue';
import PartnerTerm from '../views/PartnerTerm.vue';
import PrivacyVue from '../views/Privacy.vue';
import FaqyVue from '../views/Faq.vue';
import PartnerFaq from '../views/PartnerFaq.vue';
import ContactView from '../views/Contact.vue';
import Advertisements from '../views/Advertisements.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "MINATY（ミナティ）｜SNSを活用した紹介成立システム。集客、広告、求人をサポート",
      description:
        "MINATY（ミナティ）はSNSを活用した紹介成立システムです。紹介に特化しており、集客、広告、求人をサポートいたします。",
    },
  },
  {
    path: '/hp-partner',
    name: 'partner',
    component: Partner,
    meta: {
      title: "パートナー制度｜MINATY",
      description:
        "全国の中小企業・個人事業主さまとMINATYをつなぐマッチングパートナーさま、システム販売パートナーさまを募集しています。契約内容に応じた応じたインセンティブを受け取れます。",
    },
  },
  {
    path: '/advertisements',
    name: 'advertisements',
    component: Advertisements,
    meta: {
      title: "広告掲載｜MINATY",
      description:
        "MINATYへ広告掲載しませんか？ウェブサイトにおける企業広告を募集しております。ご利用希望のお客様はミナティにお問い合わせください。",
    },
  },
  {
    path: '/info',
    name: 'info',
    component: Info,
    meta: {
      title: "会社概要｜MINATY",
      description:
        "岐阜県にある株式会社カイプラネットでは地域経済発展を目指す中小企業・個人事業主様にDXを活用したサポートを推進しています。",
    },
  },
  {
    /* ミナティ加盟店規約 */
    path: '/terms',
    name: 'terms',
    component: TermsVue,
    meta: {
      title: "利用規約｜MINATY",
      description:
        "",
    },
  },
  {
    /* ミナティパートナー規約 */
    path: '/hp-partner/terms',
    name: 'hp-partner-terms',
    component: PartnerTerm,
    meta: {
      title: "ミナティパートナー規約｜MINATY",
      description:
        "",
    },
  },
  {
    /* 加盟店用プライバシーポリシー */
    path: '/privacy',
    name: 'privacy',
    component: PrivacyVue,
    meta: {
      title: "プライバシーポリシー｜MINATY",
      description:
        "",
    },
  },
  {
    /* よくある質問 */
    path: '/faq',
    name: 'faq',
    component: FaqyVue,
    meta: {
      title: "よくある質問｜MINATY",
      description:
        "",
    },
  },
  {
    /* パートナー よくある質問 */
    path: '/hp-partner/faq',
    name: 'hp-partner-faq',
    component: PartnerFaq,
    meta: {
      title: "よくある質問｜MINATY",
      description:
        "",
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: "お問い合わせ｜MINATY",
      description:
        "",
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition === true) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName("head");
  let gfjScript = document.createElement("script");
  gfjScript.type = "application/ld+json";
  if (to.meta.gfjScriptContent !== undefined) {
    gfjScript.innerHTML = to.meta.gfjScriptContent;
    documentHead[0].appendChild(gfjScript);
    //↑gfj用
  }
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router;