<template>
  <section>
    <div class="wrap">
      <h2>インセンティブについて</h2>
      <!-- 料金表 -->
      <img src="@/assets/partner/incentive-table.png" alt="インセンティブ料金表">
    </div>
  </section>
</template>

<script>
/* インセンティブ 料金表 */
// import IncentiveTable from '../Layout/IncentiveTable.vue';

export default {
  name: "IncentiveVue",
  components: {
    // IncentiveTable,
  }
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    h2 {
      text-align: center;
      margin-top: 0;
    }
  }
}
</style>