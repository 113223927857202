<template>
  <section id="movie-list">
    <div class="wrap">
      <div class="movie-list">
        <!-- todo:動画埋め込み -->
        <div class="movie-content">
          <h2>簡単ご利用ガイドミニＣＭ</h2>
          <div class="movie">
            <iframe src="https://www.youtube.com/embed/tML_icowdGs?si=B8EQi8-kLEu4SPmM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
        <div class="movie-content">
          <h2>ミナティ簡単使い方動画</h2>
          <div class="movie">
            <iframe src="https://www.youtube.com/embed/nmR6R3pBvr4?si=FsNbvputwCyWpEox" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MovieList"
}
</script>

<style lang="scss" scoped>
section {
  padding-top: 0;
  background-color: var(--white);
  .wrap {
    .movie-list {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .movie-content {
        width: 45%;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 30px;
        }
        h2 {
          font-size: 24px;
          color: var(--sub1);
          margin-top: 0;
        }
        .movie {
          width: 100%;
          aspect-ratio: 16 / 9;
          background-color: var(--white);
        }
        iframe {
            width: 100%;
            height: 100%;
            border: 2px solid var(--sub1);
        }
      }
    }
    .movie-link {
      display: block;
      width: 30%;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      padding: 15px 0;
      margin: 50px auto 0 auto;
      color: var(--font);
      border: 1px solid var(--font);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 50%;
        padding: 10px 0;
        margin-top: 40px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 10px 0;
        margin: 20px auto 0 auto;
      }
      &:hover {
        color: var(--white);
        border: 1px solid var(--sub1);
        background-color: var(--sub1);
      }
    }
  }
}
</style>
