<template>
  <section>
    <div class="wrap">
      <h2 class="mt-0">◾ ショップ登録について</h2>

      <h3 class="question"><span class="qa-text">Q</span>登録費用はかかりますか</h3>
      <p class="answer"><span class="qa-text">A</span>無料登録できます。</p>

      <h3 class="question"><span class="qa-text">Q</span>登録方法は</h3>
      <p class="answer mb-0"><span class="qa-text">A</span>登録用URLをメールにてお送りいたします。</p>
      <p class="answer mb-0">〜登録方法〜</p>
      <p class="index">① URLから登録（パンフレットをお持ちの方は裏面に表示されています）</p>
      <p class="index">② メールアドレスを入力し、登録用URLを取得</p>
      <p class="index mb-20">③ 必要事項の入力</p>
      
      <h3 class="question"><span class="qa-text">Q</span>パートナーから案内・紹介を受けたことがないのですが、どうすればいいですか</h3>
      <p class="answer"><span class="qa-text">A</span>パートナーさまをご紹介させていただきます。<router-link to="/contact" class="point-text underline">お問い合わせフォーム</router-link>からご連絡ください。</p>

      <h3 class="question"><span class="qa-text">Q</span>登録フォームのURLが届かないのですが、どうすればいいですか</h3>
      <p class="answer"><span class="qa-text">A</span>ご登録いただいたメールアドレスの迷惑メールボックスの中身をご確認ください。それでも見つからない場合は、お使いのメールソフトにて<span class="point-text">「send@minaty.net」</span>からのメールを受信許可に設定してください。</p>

      <h3 class="question"><span class="qa-text">Q</span>SMS（メッセージ）にhtmlが表示され、正しく表示されません。</h3>
      <p class="answer"><span class="qa-text">A</span>ミナティの登録案内メールはEメールです。SMSへ転送しているメッセージは正しく開くことができません。Eメールに届くメールアドレスで登録をお願いします。</p>

      <h3 class="question"><span class="qa-text">Q</span>メールが届きにくいアドレスはありますか</h3>
      <p class="answer mb-0"><span class="qa-text">A</span>携帯電話会社が提供するキャリアメールでは、メールが届かないことがあります。</p>
      <p class="index">■docomo：～@docomo.ne.jp</p>
      <p class="index">■au：～@ezweb.ne.jp、～@au.com</p>
      <p class="index mb-20">■Softbank：～@softbank.ne.jp、～＠i.softbank.jpなど</p>

      <h2>◾ シェア広告について</h2>

      <h3 class="question"><span class="qa-text">Q</span>シェア広告とはなんですか</h3>
      <!-- todo: 説明動画URLを設定する -->
      <p class="answer"><span class="qa-text">A</span>店舗会員登録した顧客さまが、特典付シェア広告をSNSで紹介・拡散していただくサービスになります。</p>

      <h3 class="question mt-20"><span class="qa-text">Q</span>ミナティを導入するメリットはなんですか</h3>
      <p class="answer">
        <span class="qa-text">A</span>“会員カード不要” “利用料金が低価格” “他社システムとのリンクが可能” “ニーズに合わせたポイントシステム” “シェア広告でSNSリーチに特化” “特許技術” になります。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>他社システムとのリンクについて知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>広告作成時に、転送先リンクの指定が可能です。予約システムやＳＮＳなどの転送先リンクを貼ることができるため、現在利用しているシステムとのリンクが可能です。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>どの業態がサービス対象ですか</h3>
      <p class="answer">
        <span class="qa-text">A</span>すべての業態に対応しております。ご利用店舗例：美容系サロン、飲食店、歯科クリニック、小売店、ジム他
      </p>

      <h2>◾ 利用料金について</h2>

      <h3 class="question"><span class="qa-text">Q</span>初期費用はかかりますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>初期費用は一切かかりません。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>利用料金について知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>“システム基本月額利用料”と“プラン月額利用料”は下記のとおりです。
      </p>
      <PriceTableVue class="mb-20" />

      <h3 class="question"><span class="qa-text">Q</span>利用料のプランの違いはなんですか</h3>
      <p class="answer">
        <span class="qa-text">A</span>会員さまが広告を配信できる枚数の違いです。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>追加オプションはありますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>“スーパープレミアムプラン”をお申込みの方のみ“スーパープレミアムプラン限定オプション” を追加できます。オプションを追加すると、表示できる広告枚数を増やすことができます。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>料金の支払方法を知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>月払い方式で、クレジットカードにて月額引落しです。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>クレジットカードのお引落し日は何日ですか</h3>
      <p class="answer">
        <span class="qa-text">A</span>毎月1日です。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>お申込み月の料金支払方法を知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>お申込み月の支払方法は、広告掲載をお申込みいただいた当日にクレジットカードより引落しされます。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>クレジットカードを持っていないのですが、どうすればいいですか</h3>
      <p class="answer mb-0">
        <span class="qa-text">A</span>クレジットカードをお持ちでない方は、下記のリンクよりビジネスカードのお申込みが可能です。※審査はカード会社の判断になります。審査によりお申込みの意に添えない場合がございます。あらかじめご了承ください。
      </p>
      <a href="https://www.saisoncard.co.jp/amextop/sbs-new-pn/?P5=IE4" class="answer point-text underline" target="_blank">ビジネスカードのお申込みはこちら</a>

      <h2>◾ 契約について</h2>

      <h3 class="question"><span class="qa-text">Q</span>契約期間について知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>契約期間は６ヶ月と１２ヶ月です。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>契約期間の途中で、契約期間を変更することはできますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>契約期間満了月までは、契約期間の変更はできません。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>中途解約について知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>契約期間内の中途解約はできません。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>プラン変更について知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>契約期間内でプラン変更は可能です。ただし、変更月の翌月からの適用となります。
      </p>

      <h3 class="question mt-20"><span class="qa-text">Q</span>複数店舗を運営していますが、契約は店舗ごとですか</h3>
      <p class="answer">
        <span class="qa-text">A</span>店舗ごとのご契約になります。
      </p>

      <h2>◾ 広告掲載申込について</h2>

      <h3 class="question"><span class="qa-text">Q</span>広告掲載の申込方法について知りたい</h3>
      <p class="answer mb-0">
        <span class="qa-text">A</span>下記の手順でお申込みできます。
      </p>
      <p class="answer mb-0">
        〜申込方法〜
      </p>
      <p class="index">① ショップ画面にログインする</p>
      <p class="index">② 「広告掲載作成」を選択</p>
      <p class="index">③ ご契約期間、お申込プランの選択</p>
      <p class="index">④ 利用規約に同意</p>
      <p class="index">⑤決済情報入力（クレジットカードのみ）</p>
      <p class="index mb-20">⑥ 申込完了</p>

      <h3 class="question"><span class="qa-text">Q</span>広告掲載お申込み後、すぐに利用できますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>下記の手順でお申込みできます。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>広告の作成方法を知りたい</h3>
      <p class="answer mb-0">
        <span class="qa-text">A</span>ショップ画面ログイン後、広告掲載作成、新規登録からフォーマットに沿って作成します。紹介特典情報（紹介成立した時に紹介者に付与される特典内容）と受取特典情報（ショップ来店時の特典内容）を入力します。<br>
        詳しくはショップ画面ログイン後、<span class="underline">操作方法を見る ＞ </span>をご確認ください。
      </p>
      <a class="answer underline" href="https://minaty.net/shop/login" target="_blank">ショップログインはこちら</a>

      <h3 class="question mt-20"><span class="qa-text">Q</span>広告は事前にまとめて作成することはできますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>事前にまとめて作成することが可能です。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>どのような画像を表示すればいいですか</h3>
      <p class="answer">
        <span class="qa-text">A</span>ショップさまが配信したいシェア特典とリンクした画像写真をご選択いただくと、特典内容が伝わりやすいです。例：写真に文字を入れる、具体的なサービス名、時間帯、担当スタッフ名など
      </p>

      <h3 class="question"><span class="qa-text">Q</span>月の途中ですが、広告内容を変更できますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>月の途中でも広告内容は変更できます。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>配信していた広告を公開しないに設定したあとに対象の広告を持って、お客さまが来店されました。広告の読取ができないのですが、どうしてですか</h3>
      <p class="answer">
        <span class="qa-text">A</span>広告の読取は、公開している広告のみが対象です。そのため、広告を公開するに設定後、再度広告の読取を行ってください。
      </p>

      <h2>◾ ユーザー会員登録について</h2>
      <h3 class="question"><span class="qa-text">Q</span>新規会員登録方法について知りたい</h3>
      <p class="answer mb-0">
        新規会員登録の案内はこちらの「表示するQRを選択」から表示方法を選択します。「スタッフに紐づけせずに表示」、「スタッフに紐づけて表示」の2種類から選択できます。
      </p>
      <div class="index mb-20">
        <p class="answer mb-0">1. スタッフに紐づけせずに表示</p>
        <p class="index">① QRコードのアイコンを選択</p>
        <p class="index">② （任意）初回登録時のプレゼントポイントを入力</p>
        <p class="index">③ 「QRを表示」を選択</p>
        <p class="index">④ 画面に表示されたQRコードを読取、新規会員登録へ</p>
      </div>
      <div class="index mb-20">
        <p class="answer mb-0">2. スタッフに紐づけて表示（スマートフォン端末の場合）</p>
        <p class="index">① 案内スタッフを選択</p>
        <p class="index">② QRコードのアイコンを選択</p>
        <p class="index">③ 画面に表示されたQRコードを読取、新規会員登録へ</p>
      </div>
      <div class="index mb-20">
        <p class="answer mb-0">3. スタッフに紐づけて表示（カメラ機能がない端末の場合）</p>
        <p class="index">① 案内スタッフを選択</p>
        <p class="index">② メールアイコンを選択</p>
        <p class="index">③ 顧客さまのメールアドレスを入力し送信</p>
        <p class="index">④ 受信したURLから新規会員登録へ</p>
      </div>

      <h3 class="question"><span class="qa-text">Q</span>ユーザー会員登録情報の必須項目について知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>名前、生年月日、性別、メールアドレスです。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>必須項目以外の情報を知りたいのですが、どのように取得できますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>ショップさまから会員さまに任意項目の登録をご依頼ください。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>顧客情報はどのように管理されますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>登録した顧客情報は、ショップ側のパスワード管理になります。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>すでに他の店舗で会員登録しているのですが、どのように顧客登録しますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>会員登録済の顧客はこちらの「QR表示」から、QRコードを表示し、顧客さまに読取していただくことで、会員登録ができます。
      </p>

      <h2>◾ ポイントシステムについて</h2>

      <h3 class="question"><span class="qa-text">Q</span>ポイントシステムについて知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>広告掲載をご契約のショップさまが、ご利用できるシステムです。店舗専用ポイントになりますので、オリジナルポイントでリピート強化が目指せます。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>どのようにポイント操作をしますか</h3>
      <p class="answer mb-0">
        <span class="qa-text">A</span>ユーザーの会員証を読取または、ショップ画面のポイント欄からポイント操作できます。ポイント操作方法は、お会計金額とポイント率からの自動計算と、ポイント直接入力で増減できます。<br>
        詳しくはショップ画面ログイン後、<span class="underline">操作方法を見る ＞ </span>をご確認ください。
      </p>
      <a class="answer underline" href="https://minaty.net/shop/login" target="_blank">ショップログインはこちら</a>

      <h3 class="question mt-20"><span class="qa-text">Q</span>ポイントシステムのみ利用することはできますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>ポイントシステムのみのご利用はできません。ご利用希望のショップさまは、広告掲載をお申し込みください。
      </p>

      <h2>◾ その他</h2>

      <h3 class="question"><span class="qa-text">Q</span>直接話がききたいのですが、どうすればいいですか</h3>
      <p class="answer">
        <span class="qa-text">A</span><router-link to="/contact" class="underline">お問い合わせフォーム</router-link>よりお問い合わせください。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>対応時間について知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>お問い合わせ対応時間は、平日１０時～１７時までとなっております。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>利用できる端末について知りたい</h3>
      <p class="answer">
        <span class="qa-text">A</span>パソコン端末とモバイル端末（スマートフォン・タブレット端末）でご利用いただけます。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>Windows/Macどちらでも使えますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>どちらでもご利用可能です。インターネットプラウザをご利用いただける環境があれば、どこからでもご利用いただけます。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>操作環境について制限はありますか</h3>
      <p class="answer mb-0">
        <span class="qa-text">A</span>本システムを快適にご利用いただくために、下記の利用環境を推奨します。<br>
        推奨環境以外に、特にタブレットやスマートフォンでのご利用の場合、一部の機能が正しく動作しない場合がありますので、ご了承ください。
      </p>
      <div class="index">
        <p>・OS</p>
        <p class="index">Windows 10 以上、Mac OS 10.13 以上、iOS 15 以上、Android 12 以上</p>
      </div>
      <div class="index mb-20">
        <p>・ブラウザ</p>
        <p class="index">Windows：Microsoft Edge 最新版、Google Chrome 最新版</p>
        <p class="index">Mac： Safari 最新版、Google Chrome 最新版</p>
      </div>

      <h3 class="question"><span class="qa-text">Q</span>登録した情報はどのように保護されていますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>通信の暗号化を使用して情報を保護します。
      </p>

      <h3 class="question"><span class="qa-text">Q</span>今後のサービス展開はありますか</h3>
      <p class="answer">
        <span class="qa-text">A</span>サービスのご提供が近くなりましたらご案内させていただきます。乞うご期待ください。
      </p>
    </div>
  </section>
</template>

<script>
import PriceTableVue from '@/components/Ui/PriceTable.vue';

export default {
  name: "FaqContent",
  components: {
    PriceTableVue,
  }
}
</script>

<style lang="scss" scoped>
  section {
    .wrap {
      h2 {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--font);
        margin: 60px 0 20px 0;
        color: var(--font);
      }
      .question {
        font-size: 20px;
        font-weight: 500;
        line-height: 2.5rem;
        margin: 0;
        color: var(--main);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          line-height: 2rem;
          margin-bottom: 5px;
        }
        @media screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 2rem;
        }
      }
      .answer {
        font-size: 20px;
        font-weight: 500;
        color: var(--font);
        margin-top: 0;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      .qa-text {
        margin-right: 1rem;
        font-weight: 600;
      }
      .index {
        font-size: 18px;
        margin-left: 1rem;
        color: var(--font);
      }
      .point-text {
        color: red;
      }
      .underline {
        text-decoration: underline;
      }
      .mt-20 {
        margin-top: 20px;
      }
      .mt-0 {
        margin-top: 0;
      }
      .mb-20 {
        margin-bottom: 20px;
      }
      .mb-0 {
        margin-bottom: 0;
      }
    }
  }
</style>