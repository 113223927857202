<template>
  <section>
    <div class="wrap">
      <div class="about-us">
        <h2>私たちについて</h2>
        <p>
          地域経済発展を目指す中小企業・個人事業主様にDXを活用したサポートを推進。
          <br>
          スマートな顧客管理を基に、SNSデジタル広告を中心とした、集客・広告、商品宣伝・求人募集など、様々なコンテンツに対応した、ブランディング戦略と成長を低予算にて実現。
          <br>
          ブランディングに必要な持続的広告配信をバックアップし、デジタルを用いたSDGsを目指し社会貢献へと繋げます。
        </p>
      </div>
      <div class="coo-profile">
        <div class="coo-name">
          <img src="@/assets/top/rogo.webp" alt="MINATY ロゴ">
          <p>
            取締役副社長/COO
            <br>
            平井沙也加
          </p>
        </div>
        <img class="coo-img pc-tablet-only" src="@/assets/info/gazo001.webp" alt="平井沙也加">
        <img class="coo-img sp-only" src="@/assets/info/sp_gazo001.webp" alt="平井沙也加">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style lang="scss" scoped>
section {
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
  .wrap {
    position: relative;
    .about-us {
      width: 65%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 75%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      p {
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          padding-bottom: 70px;
        }
      }
    }
    .coo-profile {
      display: flex;
      justify-content: space-around;
      position: absolute;
      right: 0;
      bottom: -160px;
      width: 60%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        bottom: -80px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        position: static;
      }
      .coo-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 55%;
        margin-top: auto;
        margin-bottom: 40px;
        @media screen and (max-width: 767px) {
          width: 45%;
          display: block;
        }
        img {
          width: 50%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        p {
          width: 45%;
          font-size: 14px;
          line-height: 18px;
          @media screen and (max-width: 767px) {
            width: 100%;
            font-size: 16px;
            margin-top: 20px;
          }
        }
      }
      .coo-img {
        width: 35%;
        @media screen and (max-width: 767px) {
          width: 45%;
        }
      }
    }
  }
}
</style>