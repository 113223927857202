<template>
  <section>
    <div class="wrap">
      <h2>パートナー登録の流れ</h2>
      <div class="flow-contents first">
        <div class="step-img">
          <img class="pc-tablet" src="@/assets/partner/icon005.webp" alt="Step1">
          <img class="sp" src="@/assets/partner/sp_icon005.webp" alt="Step1">
          <div class="rod"></div>
        </div>
        <div class="flow-content">
          <h3>ミナティにお問い合わせ</h3>
          <p>
            まずはページ下部のフォームよりお問い合わせください。訪問面談またはオンライン面談をさせていただきます。
          </p>
        </div>
      </div>
      <div class="flow-contents ">
        <div class="step-img">
          <img class="pc-tablet" src="@/assets/partner/icon006.webp" alt="Step2">
          <img class="sp" src="@/assets/partner/sp_icon006.webp" alt="Step2">
          <div class="rod"></div>
        </div>
        <div class="flow-content">
          <h3>ミナティによる審査</h3>
          <p>
            面談内容をもとにミナティ側で審査いたします。
          </p>
        </div>
      </div>
      <div class="flow-contents">
        <div class="step-img">
          <img class="pc-tablet" src="@/assets/partner/icon007.webp" alt="Step3">
          <img class="sp" src="@/assets/partner/sp_icon007.webp" alt="Step3">
          <div class="rod"></div>
        </div>
        <div class="flow-content">
          <h3>登録画面の取得・必要事項の入力</h3>
          <p>
            審査を通過した方にミナティからパートナー登録用の申し込みURLを送付いたします。
            <br>
            必要事項を入力してください。
          </p>
        </div>
      </div>
      <div class="flow-contents">
        <div class="step-img">
          <img class="pc-tablet" src="@/assets/partner/icon008.webp" alt="Step4">
          <img class="sp" src="@/assets/partner/sp_icon008.webp" alt="Step4">
          <div class="rod"></div>
        </div>
        <div class="flow-content">
          <h3>利用規約の同意</h3>
          <p>
            パートナー利用規約に同意いただける方のみ登録作業を進めてください。契約書が必要な方は、別途締結可能です。登録を進める前にお申し出ください。
          </p>
        </div>
      </div>
      <div class="flow-contents end">
        <div class="step-img">
          <img class="pc-tablet" src="@/assets/partner/icon009.webp" alt="Step5">
          <img class="sp" src="@/assets/partner/sp_icon009.webp" alt="Step5">
          <div class="rod"></div>
        </div>
        <div class="flow-content">
          <h3>登録完了</h3>
          <p>
            パートナーさま専用の管理画面を発行いたします。
            <br>
            管理画面内にオーナーさま登録用のQRコードが発行されますので、営業時にこちらをご提示ください。
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RegistrationFlow",
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  .wrap {
    h2 {
      text-align: center;
    }
    .flow-contents {
      display: flex;
      justify-content: space-between;
      width: 80%;
      max-width: 800px;
      margin: auto;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .step-img {
        width: 15%;
        @media screen and (max-width: 767px) {
          width: 20%;
        }
        .pc-tablet {
          display: none;
          @media screen and (min-width: 768px) {
            display: block;
          }
        }
        .sp {
          display: none;
          @media screen and (max-width: 767px) {
            display: block;
          }
        }
        img {
          aspect-ratio: 1 / 1;
        }
        .rod {
          width: 7px;
          min-height: 70px;
          height: 80%;
          margin: 0 auto;
          background-color: var(--main);
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            min-height: 60px;
          }
          @media screen and (max-width: 767px) {
            width: 5px;
          }
        }
      }
      .flow-content {
        width: 75%;
        h3 {
          color: var(--main);
          margin-top: 10px;
        }
        p {
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
          }
        }
      }
    }
    .first {
      margin-top: 60px;
      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
    }
    .end {
      .rod {
        display: none;
      }
    }
  }
}
</style>