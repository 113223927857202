<template>
  <div class="title">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "TopVue",
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url('@/assets/partner/title001.webp');
  aspect-ratio: 192 / 25;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    background-image: url('@/assets/partner/title001.webp');
    aspect-ratio: 16 / 4;
  }
  @media screen and (max-width: 767px) {
    background-image: url('@/assets/partner/sp_title001.webp');
    aspect-ratio: 640 / 209;
  }
  h1 {
    color: var(--white);
  }
}
</style>