<template>
  <div class="wrap">
    <router-link class="to-top" to="/">
      トップに戻る
    </router-link>
  </div>
</template>

<script>
export default {
  name: "TopButton"
}
</script>

<style lang="scss" scoped>
.to-top {
    display: block;
    width: 30%;
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    padding: 20px 0;
    border: 1px solid var(--font);
    &:hover {
      color: var(--white);
      background-color: var(--sub1);
      border-color: var(--sub1);
    }
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 40%;
    }
    @media screen and (max-width: 767px) {
      width: 60%;
      padding: 15px 0;
      margin-top: 30px;
    }
  }
</style>