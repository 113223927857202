<template>
  <section>
    <div class="wrap">
      <div class="banner">
        <h2>SNSデジタル配信サービスの<br class="sp-only">主役はあなたです！</h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SnsBanner"
}
</script>

<style lang="scss" scoped>
section {
  padding: 0;
  background-color: var(--sub1);
  .wrap {
    .banner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        padding: 30px 0;
      }
      h2 {
        text-align: center;
        color: var(--white);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 30px;
        }
        @media screen and (max-width: 767px) {
          font-size: 21px;
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>