<template>
  <section id="about-minaty">
    <div class="wrap about-content">
      <div class="message">
        <h1>MINATY<span>（ミナティ）</span>とは</h1>
        <h2 class="minaty-message">SNS紹介成立システムで広告コストを削減し<br><span>効果最適化を実現。</span>
          <!-- <br class="sp-only tablet-only">
          掲載広告からSNS広告配信へ -->
          <br>
          紹介・SNS拡散に特化した集客・広告・求人をサポート
        </h2>
      </div>
      <div class="movie-content">
          <div class="movie">
            <iframe src="https://www.youtube.com/embed/nx412QBFJX0?si=8wm08v-Yo2nLoucG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutMinaty"
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--white);
  padding-bottom: 0;
  @media screen and (max-width: 767px) {
    padding-top: 11vh;
    padding-bottom: 3vh;
    background-color: var(--sub2);
  }
  .about-content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .movie-content {
        width: 50%;
        text-align: center;
        max-height: 290px;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        iframe {
            width: 80%;
            height: 240px;
            @media screen and (max-width: 767px) {
                height: auto;
            }
        }
    }
    .message {
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h1 {
        @media screen and (max-width: 767px) {
          text-align: center;
        }
        span {
          font-size: 22px;
          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
      .minaty-message {
        font-size: 22px;
        color: var(--font);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 20px;
        }
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
        span {
          background: linear-gradient(transparent 50%, var(--marker) 50%);
        }
      }
    }
    .minaty-logo {
      width: 48%;
      background-color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        width: 100%;
        height: 200px;
        margin-top: 20px;
      }
      .logo {
        width: 75%;
        @media screen and (max-width: 767px) {
          width: 70%;
        }
      }
    }
  }
}
</style>
