<template>
  <section>
    <div class="wrap">
      <h2>会社概要</h2>
      <div class="content">
        <div class="profile-table">
          <div class="row">
            <div class="title">社名</div>
            <div class="description">株式会社カイプラネット</div>
          </div>
          <div class="row">
            <div class="title">代表者</div>
            <div class="description">代表取締役 氷室京介 </div>
          </div>
          <div class="row">
            <div class="title">本社所在地</div>
            <div class="description">
              〒501-6123
              <br>
              岐阜県岐阜市柳津町流通センター一丁目16番地3
            </div>
          </div>
          <div class="row">
            <div class="title">設立</div>
            <div class="description">平成30年12月</div>
          </div>
          <div class="row">
            <div class="title">資本金</div>
            <div class="description">30,000,000円</div>
          </div>
          <div class="row">
            <div class="title">事業内容</div>
            <div class="description">
              ・システム開発
              <br>
              ・コンサルティング
              <br>
              ・通信販売事業
            </div>
          </div>
        </div>
      </div>
      <router-link class="to-top" to="/">
        トップに戻る
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "CompanyProfile"
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  .wrap {
    h2 {
      text-align: center;
      margin-top: 0;
      margin-bottom: 60px;
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    .content  {
      width: 80%;
      max-width: 800%;
      padding: 0;
      margin: auto;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .profile-table {
        width: 100%;
        border-top: 1px solid var(--font);
        .row {
          display: flex;
          padding: 15px 0;
          border-bottom: 1px solid var(--font);
          @media screen and (max-width: 767px) {
            padding: 10px 0;
          }
          .title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            border-right: 1px solid var(--font);
            font-size: 20px;
            line-height: 40px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 16px;
              line-height: 30px;
            }
            @media screen and (max-width: 767px) {
              font-size: 16px;
              line-height: 25px;
            }
          }
          .description {
            width: 70%;
            padding-left: 20px;
            font-size: 20px;
            line-height: 40px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 16px;
              line-height: 30px;
            }
            @media screen and (max-width: 767px) {
              font-size: 16px;
              line-height: 25px;
            }
          }
        }
      }
    }
    .to-top {
      display: block;
      width: 30%;
      margin-top: 60px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      padding: 20px 0;
      border: 1px solid var(--font);
      &:hover {
        color: var(--white);
        background-color: var(--sub1);
        border-color: var(--sub1);
      }
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 40%;
      }
      @media screen and (max-width: 767px) {
        width: 60%;
        padding: 15px 0;
        margin-top: 30px;
      }
    }
  }
}
</style>