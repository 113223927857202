<template>
  <section>
    <div class="wrap">
      <div class="banner">
        <h2>もっと詳しくは無料登録にて</h2>
        <a class="banner-link" v-scroll-to="'#contact'">登録はこちら</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RegistrationBanner"
}
</script>

<style lang="scss" scoped>
section {
  padding: 0;
  background-color: var(--sub1);
  .wrap {
    .banner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        padding: 30px 0;
      }
      h2 {
        text-align: center;
        width: 50%;
        color: var(--white);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 60%;
          font-size: 30px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          font-size: 21px;
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
      .banner-link {
        text-align: center;
        width: 15%;
        padding: 10px 0;
        color: var(--white);
        border: 1px solid var(--white);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 20%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        &:hover {
          color: var(--sub1);
          background-color: var(--white);
          border: 1px solid var(--sub1);
        }
      }
    }
  }
}
</style>