<template>
  <div>
    <h4 class="usage-fee-top">
      システム基本月額利用料
    </h4>
    <div class="usage-fee-table">
      <div class="usage-fee">
        <div class="fee-title">
          契約期間
        </div>
        <div class="fee">
          月額利用料
        </div>
      </div>
      <div class="usage-fee">
        <div class="fee-title">
          6ヶ月
        </div>
        <div class="fee">
          4,000円
          <br>
          (税込4,400円)
        </div>
      </div>
      <div class="usage-fee low-price">
        <div class="fee-title low-price-title">
          12ヶ月
        </div>
        <div class="fee low-price-fee">
          3,000円
          <br>
          (税込3,300円)
        </div>
      </div>
    </div>
    <div class="message-section">
      <p>年間<span>12,000</span>円もお得！</p>
      <img src="@/assets/top/icon013.webp" alt="12ヶ月がおすすめ">
    </div>

    <h4>プラン月額利用料</h4>
    <div class="plan-table">
      <div class="column">プラン名<br class="sp-only"><span class="sp-only">広告セット枚数</span></div>
      <div class="column pc">広告セット枚数</div>
      <div class="column">月額利用料</div>
      <div class="column last">1セット単価</div>
    </div>
    <div class="plan-table">
      <div class="column low">ノーマル<br class="sp-only"><span class="sp-only">1セット</span></div>
      <div class="column low pc">1セット</div>
      <div class="column low">1,500円<br>(税込1,650円)</div>
      <div class="column low last">1,500円<br>(税込1,650円)</div>
    </div>
    <div class="plan-table">
      <div class="column low border">ライト<br class="sp-only"><span class="sp-only">3セット</span></div>
      <div class="column low border pc">3セット</div>
      <div class="column low border">3,000円<br>(税込3,300円)</div>
      <div class="column low last border">1,000円<br>(税込1,100円)</div>
    </div>
    <div class="plan-table">
      <div class="column low">スタンダード<br class="sp-only"><span class="sp-only">5セット</span></div>
      <div class="column low pc">5セット</div>
      <div class="column low">4,500円<br>(税込4,950円)</div>
      <div class="column low last">900円<br>(税込990円)</div>
    </div>
    <div class="plan-table">
      <div class="column low border">プレミアム<br class="sp-only"><span class="sp-only">7セット</span></div>
      <div class="column low border pc">7セット</div>
      <div class="column low border">5,600円<br>(税込6,160円)</div>
      <div class="column low last border">800円<br>(税込880円)</div>
    </div>
    <div class="plan-table">
      <div class="column low">スーパー<br class="sp-only">プレミアム<br class="sp-only"><span class="sp-only">10セット</span></div>
      <div class="column low pc">10セット</div>
      <div class="column low">7,000円<br>(税込7,700円)</div>
      <div class="column low last">700円<br>(税込770円)</div>
    </div>
    <div class="plan-table table-last">
      <div class="column low border">スーパー<br class="sp-only">プレミアム<br>限定オプション<br class="sp-only"><span class="sp-only">5セット</span></div>
      <div class="column low border pc">5セット</div>
      <div class="column low border">2,500円<br>(税込2,750円)</div>
      <div class="column low last border">500円<br>(税込550円)</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceTable",
}
</script>

<style lang="scss" scoped>
  h4 {
    font-size: 24px;
    padding: 20px;
    margin-top: 0;
    margin-bottom: 40px;
    color: var(--white);
    background-color: var(--sub1);
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  /* システム基本利用料 */
  .usage-fee-table {
    display: flex;
    .usage-fee {
      width: calc(100%/3);
      border: 1px solid var(--font);
      border-right: 0;
      .fee-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        font-size: 20px;
        background-color: var(--gold);
        border-bottom: 1px solid var(--font);
        @media screen and (max-width: 767px) {
          font-size: 15px;
        }
      }
      .fee {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 90px;
        font-size: 24px;
        line-height: 30px;
        background-color: var(--white);
        @media screen and (max-width: 767px) {
          font-size: 15px;
        }
      }
      .low-price-title {
        height: 57px;
        font-weight: 500;
      }
      .low-price-fee {
        font-weight: 500;
      }
    }
    .low-price {
      border: 4px solid var(--main);
    }
  }
  .message-section {
    display: flex;
    justify-content: end;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
      margin-top: auto;
      margin-bottom: 10px;
      color: var(--main);
      @media screen and (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 0;
      }
      span {
        font-size: 28px;
        @media screen and (max-width: 767px) {
          font-size: 22px;
        }
      }
    }
    img {
      width: 10%;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 15%;
      }
      @media screen and (max-width: 767px) {
        width: 25%;
      }
    }
  }
  /* 広告デジタルチケット月額利用料 */
  .usage-fee-top {
    position: relative;
    .point-message-img {
      position: absolute;
      top: -120px;
      right: 0;
      width: 250px;
      height: 115px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 200px;
        height: 92px;
        top: -100px;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .plan-table {
    display: flex;
    .column {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: calc(100%/4);
      height: 60px;
      border: 1px solid var(--font);
      border-right: 0;
      background-color: var(--gold);
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        align-content: center;
        width: calc(100%/3);
        font-size: 15px;
      }
      span {
        width: 100%;
      }
    }
    .pc {
      display: flex;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .low {
      height: 70px;
      background-color: var(--white);
      border: 1px solid var(--font);
      border-top: 0;
      border-right: 0;
      @media screen and (max-width: 767px) {
        height: 130px;
      }
    }
    .last {
      border-right: 1px solid var(--font);
    }
    .border {
      background-color: var(--sub2);
    }
  }
  .table-last {
    margin-bottom: 60px;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
</style>