<template>
  <div class="ui-drawer">
    <div class="drawer-wrap">
      <div class="btn-trigger" @click="hamburgerSet">
        <img
          src="@/assets/top/top_ham.webp"
          alt="ハンバーガーメニュー"
          class="drawer-open"
        />
        <img
          src="@/assets/top/top_batu.webp"
          alt="ハンバーガーメニュー 閉じる"
          class="drawer-close"
          style="display: none"
        />
      </div>
      <div
        class="drawer-menu"
        :class="{
          'open-drawer': openDrawer === true,
          'close-drawer': openDrawer === false,
        }"
      >
        <div class="drawer-header"></div>
        <div @click="hamburgerSet" class="drawer-body">
          <div class="drawer-item">
            <div class="drawer-link" @click="top">トップ</div>
            <div v-if="this.$route.path === '/'" class="drawer-desc-link">
              <div class="drawer-desc-link" v-scroll-to="'#about-minaty'">
                ミナティとは
              </div>
              <div class="drawer-desc-link" v-scroll-to="'#movie-check'">
                サービス内容【動画】
              </div>
              <div class="drawer-desc-link" v-scroll-to="'#system-features'">
                システム機能
              </div>
              <div class="drawer-desc-link" v-scroll-to="'#use-case'">
                活用事例
              </div>
              <div class="drawer-desc-link" v-scroll-to="'#ad-fee'">
                利用料金
              </div>
              <div class="drawer-desc-link" v-scroll-to="'#faq'">
                よくある質問
              </div>
              <div class="drawer-desc-link" v-scroll-to="'#contact'">
                お問い合わせ
              </div>
            </div>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/hp-partner">
              パートナー制度
            </router-link>
          </div>
          <div class="drawer-item">
            <router-link class="drawer-link" to="/advertisements">
              広告掲載
            </router-link>
          </div>
          <div class="drawer-item last">
            <router-link class="drawer-link" to="/info">
              会社概要
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="hamburgerSet"
      class="back-gray"
      :class="{ 'back-gray-show': openDrawer === true }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
      active: false,
      open: false,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
      document.body.classList.toggle('no-scroll', this.openDrawer);
    },
    hamburgerButton() {
      this.active = this.active === false ? true : false;
    },
    imgToggle() {
      const openImg = document.querySelector(".drawer-open");
      const closeImg = document.querySelector(".drawer-close");
      const topScroll = document.querySelector(".top-scroll")
        ? document.querySelector(".top-scroll")
        : null;
      this.open = !this.open;
      if (this.open === true) {
        openImg.style.display = "none";
        closeImg.style.display = "block";
        if (topScroll === null) {
          // no action
        } else {
          topScroll.style.display = "none";
        }
      } else {
        openImg.style.display = "block";
        closeImg.style.display = "none";
        if (topScroll === null) {
          // no action
        } else {
          topScroll.style.display = "block";
        }
      }
    },
    hamburgerSet() {
      this.imgToggle();
      this.switchDrawer();
      this.hamburgerButton();
    },
    top() {
      if (this.$route.path === "") {
        this.$scrollTo("#app", 500, { easing: "ease" });
      } else {
        //遷移する
        this.$router.push("/");
        this.$scrollTo("#app", 500, { easing: "ease" });
      }
    },
    contact() {
      if (this.$route.path === "/") {
        this.$scrollTo("#contact", 500, { easing: "ease" });
      } else {
        //遷移する
        this.$router.push("/");
        this.$scrollTo("#contact", 500, { easing: "ease" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-scroll {
  overflow: hidden;
}

$width: 280px;
.drawer-wrap {
  overflow-y: auto;
  .drawer-button {
    width: 50px;
    cursor: pointer;
  }
  .drawer-menu {
    transition: all 1s 0s ease;
    position: fixed;
    top: 0;
    background-color: rgba(130, 130, 130, 0.9);
    box-sizing: border-box;
    z-index: 999;
    width: $width;
    height: 100vh;

    .drawer-body {
      padding-top: 80px;
      padding-left: 20px;
      padding-right: 3vw;
      @media screen and (max-width: 767px) {
        padding-top: 70px;
        padding-right: 5vw;
      }
      .drawer-item {
        text-align: initial;
        transition: all 1s;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 7px;
        transition: all 0.5s; //普通のリストは消す
        position: relative; //普通のリストは消す
        z-index: 2; //普通のリストは消す
        border-top: 1px solid var(--white);
        &:hover {
          cursor: pointer;
        }
        .drawer-desc-link {
          padding-left: 10px;
        }
        span {
          margin-left: 10px;
        }

        a,
        .drawer-link {
          width: 100%;
        }

        a,
        span,
        .drawer-link,
        .drawer-desc-link {
          font-weight: 500;
          text-decoration: none;
          color: white;
          position: relative;
          display: inline-block;
          &:hover {
            color: var(--main);
          }
        }
        .drawer-desc-link {
          display: block;
          margin: 10px 0;
          font-weight: 400;
          @media screen and (max-width: 767px) {
            margin: 5px 0;
          }
        }
      }
      .last {
        border-bottom: 1px solid var(--white);
      }
    }
  }
}

.open-drawer {
  opacity: 1;
  transform: translateX(-$width);
  right: -$width;
}

.close-drawer {
  opacity: 0;
  transition: opacity 3s linear 2s;
  transform: translateX($width);
  right: -$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
}

.back-gray-show {
  display: initial;
}

.btn-trigger {
  position: relative;
  width: 40px;
  height: 100%;
  cursor: pointer;
  z-index: 1000;
  img {
    margin: auto;
    display: block;
  }
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(20px) rotate(45deg);
  }
  50% {
    transform: translateY(20px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.kakko {
  font-size: 14px;
  margin-left: 5px;
}
</style>
