<template>
  <div class="">
    <div class="header"></div>
    <Top title="加盟店用プライバシーポリシー" />
    <section>
      <div class="wrap">
        <h2 class="desc-sub-title">
          株式会社カイプラネット（以下「当社」といいます。）は、当社の運営するウェブサイト（名称：ミナティ、以下「本サイト」といいます。）上で提供するサービス（以下「本サービス」といいます。）に関する加盟店登録申請者および加盟店（以下、あわせて「お客様」といいます。）の情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
        </h2>

        <h3>第１条（定義）</h3>
        <p>
          本ポリシーにおける用語の定義は、以下のとおりとします。
          <br>
          <span class="indent">⑴ 「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）または個人識別符号が含まれるものをいいます。</span>
          <span class="indent">⑵ ｢お客様情報｣とは、個人情報の他、お客様およびお客様の所属する法人・団体・事業所等（以下「法人等」といいます。）に関する情報で、法人等の名称、部署名、責任者名、担当者名、メールアドレス、所在地、電話・ファクシミリ番号、ＳＮＳアカウント、本サービスの取引内容、問合せ・連絡内容、金融機関口座等の決済情報、クッキーＩＤ等のオンライン上の識別子、利用の端末情報、位置情報、閲覧履歴、その他本サービスの利用状況等の情報をいいます。</span>
        </p>

        <h3>第２条（お客様情報取扱い事業者）</h3>
        <p>
          株式会社カイプラネット
        </p>
        <p>
          岐阜市柳津町流通センター１丁目１６番地３
        </p>
        <p>
          代表取締役 氷室京介
        </p>

        <h3>第３条（利用目的）</h3>
        <p>
          当社は、取得したお客様情報を以下の目的で利用します。 
          <br>
          <span class="indent">⑴ 加盟店登録、ログイン時における本人確認、本サイトへの広告掲載、本サービス利用料の請求等、本サービスの提供、維持、保護および改善のため</span> 
          <span class="indent">⑵ 本サービスに関する案内ならびにお客様、本サイトの会員および第三者からの問い合わせ等への対応のため</span> 
          <span class="indent">⑶ 本サービスに関する当社の規約等に違反する行為および不正行為への対応のため</span> 
          <span class="indent">⑷ 規約等の変更などを通知するため</span> 
          <span class="indent">⑸ 本サービスに関連して、個人を識別できない形式に加工した統計データ等を作成するため</span> 
          <span class="indent">⑹ マーケティングデータの調査および分析ならびにマーケティング施策の検討および実施のため</span> 
          <span class="indent">⑺ 当社または第三者の商品およびサービスの提供、勧誘、広告、その他マーケティングの実施のため</span> 
          <span class="indent">⑻ キャンペーン、懸賞企画、アンケートの実施のため</span> 
          <span class="indent">⑼ 新たな商品およびサービスの企画、研究および開発のため</span>
          <span class="indent">⑽ 上記目的に付随する利用目的のため</span>
        </p>

        <h3>第４条（第三者への開示・提供）</h3>
        <ol>
          <li>
            当社は、以下のいずれかに該当する場合には、お客様情報を第三者に開示・提供することができます。
            <br>
            <span class="indent">⑴ お客様の事前の同意を得た場合</span>
            <span class="indent">⑵ 本サービスを提供するために合理的に必要な場合</span>
            <span class="indent">⑶ 当社が利用目的の達成に必要な範囲内においてお客様情報の取扱いを含む業務の全部または一部を委託する場合</span>
            <span class="indent">⑷ 当社の営業代理店その他取引先に対し、お客様情報を通知する必要がある場合</span>
            <span class="indent">⑸ お客様の支払能力の調査のために、個人信用情報機関に照会の必要がある場合</span>
            <span class="indent">⑹ 合併その他の事由による事業の承継に伴ってお客様情報が提供される場合</span>
            <span class="indent">⑺ 人の生命、身体または財産の保護のために必要がある場合であって、お客様の同意を得ることが困難である場合</span>
            <span class="indent">⑻ 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難である場合</span>
            <span class="indent">⑼ 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行するに対して協力する必要がある場合であって、お客様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</span>
            <span class="indent">⑽ 個人情報保護法その他の法令で認められる場合</span>
          </li>
          <li>当社は、取得したお客様情報を適切に管理し、前項の場合を除き、お客様情報を第三者に開示・提供することはありません。</li>
        </ol>

        <h3>第５条（業務委託）</h3>
        <p>当社は、お客様情報の利用目的の達成に必要な範囲内でお客様情報の取扱業務を外部に委託することがあります。この場合、当社は、委託先に対して必要かつ適切な監督を行います。</p>

        <h3>第６条（安全管理措置）</h3>
        <p>当社は、お客様情報につき、その重要性を従業員に認識させ、当社が保有するお客様情報への不正アクセス、紛失、破壊、改ざん、漏洩等が行われないよう、合理的な安全管理措置を講じ管理します。また、必要に応じこの措置の見直し・改善を継続的に行い、安全性の確保に努めます。当社が講じる安全管理措置については、次条の「お問い合わせ窓口」までお問い合わせください。</p>

        <h3>第７条（開示、訂正、利用停止等）</h3>
        <ol>
          <li>当社は、お客様より自身のお客様情報について開示、訂正、利用の停止等の請求があった場合には、当社所定の手続に基づいて、お客様ご本人または代理権限を有する者であることを確認の上、適切に対応します。</li>
          <li>お客様情報の開示、訂正、利用の停止等をご希望される場合ならびにお客様情報の取扱いに関するお問い合わせおよび苦情がある場合には、以下のお問い合わせ窓口までご連絡ください。なお、情報の開示にあたっては、別途実費を請求させていただく場合がありますので、ご了承ください。</li>
        </ol>

        <h4>【お問い合わせ窓口】</h4>
        <p>電話番号：０１２０－８６７－８６８ 受付時間（平日１０～１７時）</p>
        <p>メールアドレス：info@minaty.net</p>

        <h3>第８条（プライバシーポリシーの変更手続き）</h3>
        <p>当社は、お客様情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。変更した場合には、本サイト上で告知する方法によりお客様に通知します。ただし、法令上、お客様の同意が必要となるような内容の変更の場合には、当社所定の方法でお客様の同意を得るものとします。</p>

        <p class="end">以上</p>
        <p class="terms">【２０２４年６月１７日制定】</p>
      </div>
    </section>
    <TopButtonVue class="top" />
  </div>
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import TopButtonVue from '@/components/Ui/TopButton.vue';

export default {
  name: "PrivacyVue",
  components: {
    Top,
    TopButtonVue,
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
section {
  .wrap {
    .desc-sub-title {
      font-size: 16px;
      font-weight: 300;
      color: var(--font);
      line-height: 1.5rem;
      text-indent: 1rem;
    }
    h3 {
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    ol {
      @media screen and (max-width: 767px) {
        padding-inline-start: 25px;
      }
      li {
        line-height: 1.5rem;
        margin-bottom: 5px;
      }
    }
    p {
      text-indent: 1rem;
    }
    .indent {
      display: block;
      margin-left: 1rem;
    }
    .end {
      text-indent: 0;
      text-align: end;
      margin-top: 20px;
    }
    .terms {
      text-indent: 0;
      margin-top: 20px;
    }
  }
}
.top {
  margin: 20px auto;
}
</style>