<template>
  <section id="faq">
    <div class="wrap">
      <h2>よくある質問</h2>
      <h3>法人・個人事業主の方</h3>
      <div class="faq">
        <div class="question">
          <div class="question-icon">Q</div>
          <h4 class="puestion-title">ミナティを利用したいのですが、申し込み方法がわかりません。</h4>
        </div>
        <p class="answer">パートナー（販売代理店）からのみの申込となっておりますので、お問い合わせフォームより依頼をしていただきますと、パートナーをご紹介いたします。</p>
      </div>
      <div class="faq">
        <div class="question">
          <div class="question-icon">Q</div>
          <h4 class="puestion-title">運営する店舗が複数あるのですが、一つのアカウントで登録することはできますか？</h4>
        </div>
        <p class="answer">店舗を追加することは可能です。まずは本社情報を登録していただきます。</p>
      </div>
      <div class="faq">
        <div class="question">
          <div class="question-icon">Q</div>
          <h4 class="puestion-title">操作環境について制限はありますか？</h4>
        </div>
        <p class="answer">
          本システムを快適にご利用いただくために、下記の利用環境を推奨します。
          <br>
          推奨環境以外に、特にタブレットやスマートフォンでのご利用の場合、一部の機能が正しく動作しない場合がありますので、ご了承ください。
          <br>
          ・OS Windows 10 以上、Mac OS 10.13 以上、iOS 15 以上、Android 12 以上
          <br>
          ・ブラウザ
          <br>
          Windows：Microsoft Edge 最新版、Google Chrome 最新版
          <br>
          Mac： Safari 最新版、Google Chrome 最新版
        </p>
      </div>
    </div>
    <a class="more-link" href="/faq">もっと見る</a>
  </section>
</template>

<script>
export default {
  name: "FaqVue"
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    h2 {
      text-align: center;
      margin: 0;
    }
    h3 {
      font-size: 24px;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        margin: 20px 0;
      }
    }
    .faq {
      padding: 40px 60px;
      margin-top: 20px;
      background-color: var(--sub2);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
      }
      @media screen and (max-width: 767px) {
        padding: 0;
        background-color: var(--white);
      }
      .question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
          padding: 20px 0;
          background-color: var(--sub2);
          justify-content: space-around;
        }
        .question-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          height: auto;
          min-height: 40px;
          font-size: 28px;
          font-weight: 400;
          line-height: 38px;
          color: var(--main);
          border-right: 1px solid var(--main);
          border-left: 1px solid var(--main);
          @media screen and (max-width: 767px) {
            padding: 0;
            font-size: 18px;
            line-height: 25px;
          }
        }
        .puestion-title {
          font-size: 28px;
          line-height: 38px;
          font-weight: 400;
          width: 85%;
          margin: 0;
          color: var(--main);
          @media screen and (max-width: 767px) {
            padding: 0;
            width: 75%;
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
      .answer {
        width: 85%;
        margin-left: auto;
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
  }
  .more-link {
    margin: 20px auto 20px auto;
    display: block;
    width: 50%;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 15px 0;
    margin-top: 60px;
    border: 1px solid var(--font);
  }
}
</style>