<template>
  <div class="header"></div>
  <Top title="よくある質問" />
  <FaqContent />
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import FaqContent from '@/components/faq/Content.vue';

export default {
  name: "FaqVue",
  components: {
    Top,
    FaqContent
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
</style>