<template>
  <div class="header"></div>
  <Top title="パートナー よくある質問" />
  <PartnerFaqContent />
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import PartnerFaqContent from '@/components/faq/PartnerContent.vue';

export default {
  name: "PartnerFaq",
  components: {
    Top,
    PartnerFaqContent
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
</style>