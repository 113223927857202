<template>
  <div class="layout-header">
    <div
      class="header"
      :class="{
        'over-baseline':
          (scrollY >= 1000 && path === '/') || (scrollY >= 0 && path !== '/'),
      }"
    >
      <div class="header-cover">
        <div class="header-left">
          <div class="to-top" @click="top">
            <img src="@/assets/top/rogo.webp" alt="MINATY" />
          </div>
        </div>

        <div class="header-right">
          <a class="contact-btn pc-tablet-only" href="/shop/login">
            ショップログインはこちら
          </a>
          <div class="hamburger">
            <UiDrawer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "@/components/Ui/Drawer.vue";

export default {
  name: "LayoutHeader",
  components: {
    UiDrawer,
  },
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      //  documentheight:0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            // console.log(this.documentheight - this.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);//長さはかるコンソール
          }.bind(this),
          200
        );
      }
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--main);
}
.st1 {
  fill: none;
}

.header {
  background-color: var(--white);
  width: 100vw;
  height: 8vh;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  top: 0;
  left: 0;
  @media screen and (max-width: 767px) {
    height: 10vh;
  }
  .header-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 3%;
    margin-left: 2%;
    width: 96%;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      margin-right: 5%;
    }
  }
  .header-left {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      width: 45%;
    }
    .to-top {
      cursor: pointer;
      display: flex;
      text-align: center;
      img {
        width: 100%;
      }
    }
    .to-top:hover {
      opacity: 0.8;
    }
  }
  .header-right {
    width: 330px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      width: 30%;
      justify-content: flex-end;
    }
    .contact-btn {
      margin: auto 0;
      border: 1px solid var(--font);
      padding: 10px 20px;
      color: var(--font);
      &:hover {
        color: var(--white);
        background-color: var(--sub1);
        border-color: var(--sub1);
      }
    }
  }
}
</style>
