<template>
    <Top />
    <AboutMinaty />
    <SellingPoint />
    <MovieCheck />
    <ReasonVue />
    <SystemFeatures />
    <MovieList />
    <RegistrationBanner />
    <UseCases />
    <AdBranding />
    <SnsBanner />
    <FaqVue />
    <ContactVue />
    <DescriptionLink />
</template>

<script>
import Top from '@/components/top/Top.vue';
import AboutMinaty from '@/components/top/AboutMinaty.vue';
import SellingPoint from '@/components/top/SellingPoint.vue';
import MovieCheck from '@/components/top/MovieCheck.vue';
import ReasonVue from '@/components/top/Reason.vue';
import SystemFeatures from '@/components/top/SystemFeatures.vue';
import MovieList from '@/components/top/MovieList.vue';
import RegistrationBanner from '@/components/top/RegistrationBanner.vue';
import UseCases from '@/components/top/UseCases.vue';
import AdBranding from '@/components/top/AdBranding.vue';
import SnsBanner from '@/components/top/SnsBanner.vue';
import FaqVue from '@/components/top/Faq.vue';
import ContactVue from '@/components/top/Contact.vue';
import DescriptionLink from '@/components/Ui/DescriptionLink.vue';

export default {
  name: 'HomeView',
  components: {
    Top,
    AboutMinaty,
    SellingPoint,
    MovieCheck,
    ReasonVue,
    SystemFeatures,
    MovieList,
    RegistrationBanner,
    UseCases,
    AdBranding,
    SnsBanner,
    FaqVue,
    ContactVue,
    DescriptionLink,
  }
}
</script>
