<template>
  <div class="">
    <div class="header"></div>
    <Top title="ミナティパートナー規約" />
    <section>
      <div class="wrap">
        <h2 class="desc-sub-title">
          本規約は、株式会社カイプラネット（以下「当社」という。）と受託者が締結するミナティパートナー契約（業務委託契約、以下「本契約」という。）の内容を定めるものである。受託者は、本規約の全ての条項を承諾し、遵守しなければならないものとする。
        </h2>

        <h3>第１条（委託業務）</h3>
        <ol>
          
          <li>
            当社は受託者に対し、当社が運営するウェブサイト（名称：ミナティ、以下「本サイト」という。）に関する以下の各号に掲げる業務（以下「本業務」という。）を委託し、受託者はこれを受託する。
            <br>

            <span class="indent">
              ⑴ 広告掲載サービス利用契約（以下「本利用契約」という。）の新規顧客の開拓及び当社に対する紹介
            </span>
              <br>
            <span class="indent">
              なお、新規顧客とは、当社との間で、過去に１度も本利用契約を締結したことがない者をいうものとする。
            </span>
            <br>
            <span class="indent">
              ⑵ その他前号に附帯又は関連する一切の業務
            </span>
          </li>
          <li>受託者は、本契約、本業務の遂行に関して当社に適用される法令、監督官庁の告示・通達及び業界の自主ルール等並びに当社からの指示を誠実に遵守し、善良なる管理者の注意をもって本業務を遂行しなければならない。</li>
        </ol>

        <h3>第２条（受託者の地位）</h3>
        <p class="intent">
          受託者は、いかなる目的及び場合であっても当社の代理人ではないことを確認する。
        </p>

        <h3>第３条（専用ＱＲコード）</h3>
        <ol>
          <li>当社は、受託者に対し、本契約締結後速やかに専用のＱＲコード（以下「本件ＱＲコード」という。）を発行する。</li>
          <li>受託者は、自己の責任において、本件ＱＲコードを適切に管理及び保管するものとし、これを第三者に利用させ、又は貸与、譲渡、売買等をしてはならない。</li>
        </ol>

        <h3>第４条（顧客紹介）</h3>
        <ol>
          <li>第１条第１項第１号の業務に関し、受託者が営業を行った新規顧客が当社と本利用契約を締結するにあたり、前条第１項により付与された受託者の本件ＱＲコードから申込みをした場合に限り、受託者が当社に対し顧客を紹介したものとみなす。</li>
          <li>前項により受託者が当社に対し顧客を紹介した場合に限り、当社は、受託者に対し、第６条に定める業務委託料を支払う。</li>
        </ol>

        <h3>第５条（顧客紹介の結果通知）</h3>
        <p>当社は、受託者が営業を行った顧客が前条第１項の方法により申込みを行い、当社と本利用契約を締結した場合には、受託者に対し、同契約締結後１４日以内に契約締結の事実を通知する。</p>

        <h3>第６条（業務委託料等）</h3>
        <ol>
          <li>当社は、受託者から紹介を受けた顧客と本利用契約を締結し、同契約に基づく代金の支払いを受けた場合、受託者に対し、本業務の対価（以下「本委託料」という。）として、当社が作成する支払条件通知書（以下「本通知書」という。）に定める条件に従い算出する金額を支払う。</li>
          <li>当社は、当月分の本委託料について、当月１日から当月末日までに顧客から支払いを受けた金額（決済会社からの立替払分を含む。）を基に算出する。</li>
          <li>受託者は、当社に対し、当月分の事務手数料として、本通知書に定める条件に従い算出する金額を支払う。</li>
          <li>当社は、受託者に対し、当月分の委託料から当月分の事務手数料を控除した金額を、翌月末日までに受託者が指定する口座に振り込む方法により支払う。なお、振込手数料は当社の負担とする。但し、当該支払金額が当社所定の金額に満たない場合には、振込手数料は受託者の負担とする。</li>
          <li>当社は、７日前までに受託者に通知することにより、いつでも本通知書の内容を変更することができる。但し、変更前に顧客と締結した本利用契約に関する本委託料については影響しないものとする。</li>
          <li>当社は、本契約が第１９条第１項に基づく当社からの中途解約により終了した場合、又は第１５条第３項若しくは第２０条に基づく受託者からの解除により終了した場合には、本契約終了日の翌日から１年以内に発生する本委託料に限り、受託者に対し、第１項及び第２項に基づき支払い、これをもって、受託者に対する一切の本委託料の支払いを終了する。</li>
          <li>当社は、本契約が期間満了により終了した場合、本契約が第１９条第１項に基づく受託者からの中途解約により終了した場合、又は第１５条第３項若しくは第２０条に基づく当社からの解除により終了した場合には、本契約終了時点で発生している本委託料の支払いをもって、受託者に対する一切の本委託料の支払いを終了する。</li>
          <li>当社は、受託者との間に本契約又はその他の契約等に基づく債権債務が存在する場合には、各債務の弁済期の到来の前後にかかわらず、これを任意に相殺することができる。</li>
          <li>受託者は、理由の如何にかかわらず、当社が顧客に本利用契約の代金を返金した場合には、当社に対し、当該返金分に係る本委託料を、当社の請求月の翌月末日までに返金しなければならない。</li>
        </ol>

        <h3>第７条（経費負担）</h3>
        <ol>
          <li>当社は、受託者に対し、本サイトに関する資料、パンフレット等（以下「本資料等」という。）を無償で提供し、また、営業用のデモ画面（以下「本デモ画面」という。）の無償使用を認める。但し、これらの知的財産権は当然に当社に留保され、受託者は、これらを本契約以外の目的に使用してはならない。</li>
          <li>前項の場合を除き、受託者が本業務を遂行するにあたり生じた費用は、全て受託者の負担とする。</li>
        </ol>

        <h3>第８条（受託者の報告義務）</h3>
        <p>受託者は、他社による当社の知的財産権の侵害行為を発見した場合には、直ちに当社に報告しなければならない。</p>

        <h3>第９条（競業避止義務）</h3>
        <p>受託者は、本契約期間中、当社の書面による事前の同意なく、自ら又は第三者をして本利用契約と競合するサービスを提供し、又は本利用契約と競合する他社のサービスの提供促進活動をしてはならない。</p>

        <h3>第１０条（権利義務の譲渡禁止）</h3>
        <p>受託者は、当社の書面による事前の同意なく、本契約に定める自己の権利又は義務を、第三者に譲渡し、又は担保に供し、その他一切の処分をしてはならない。</p>

        <h3>第１１条（再委託の禁止）</h3>
        <ol>
          <li>受託者は、当社の書面による事前の同意なく、本業務の全部又は一部を第三者に委託してはならない。</li>
          <li>受託者は、当社の承諾を得て第三者（以下「再委託先」という。）に再委託する場合には、本契約に基づき受託者が負う義務と同等の義務を再委託先に負わせるものとし、かつ再委託をその理由として、本契約に基づく自らの義務を何ら免れない。</li>
          <li>当社は、承諾をした後であっても、再委託先に再委託することが不適切となる合理的な理由が存する場合には、受託者に書面によりその理由を通知することにより、再委託の中止を請求することができる。</li>
        </ol>

        <h3>第１２条（通知義務）</h3>
        <p>
          受託者は、以下の各号のいずれかに該当する事実が生じた場合には、速やかに当社に通知しなければならない。
          <br>
          <span class="indent">⑴ 住所、連絡先、代表者、商号又は本契約に関連する組織の変更</span>
          <br>
          <span class="indent">⑵ 事業譲渡、合併その他これに準ずる経営上の重要事項の変動</span>
          <br>
          <span class="indent">⑶ 第１５条第１項又は第２項の確約に関する虚偽申告又は違反</span>
        </p>

        <h3>第１３条（秘密保持義務）</h3>
        <ol>
          <li>
            当社及び受託者は、本契約によって知り得た相手方の営業上又は技術上の情報（以下「秘密情報」という。）について、善良なる管理者の注意をもって管理し、第三者に開示又は漏洩してはならず、かつ、本契約以外の目的に使用してはならない。但し、以下の各号のいずれかに該当する情報については、この限りでない。
            <br>
            <span class="indent">⑴ 開示を受けた時点において、既に公知の情報</span>
            <br>
            <span class="indent">⑵ 開示を受けた時点において、情報受領者が既に正当に保有していた情報</span>
            <br>
            <span class="indent">⑶ 開示を受けた後に、情報受領者の責に帰すべき事由によらずに公知となった情報</span>
            <br>
            <span class="indent">⑷ 開示を受けた後に、情報受領者が正当な権限を有する第三者から秘密保持義務を負うことなく正当に入手した情報</span>
            <br>
            <span class="indent">⑸ 情報受領者が秘密情報を利用することなく独自に開発した情報</span>
          </li>
          <li>当社及び受託者は、秘密情報の開示を受けた自己の役員及び従業員等が、本契約に基づき、自己が負担する義務と同等の義務を履行することを保証する。</li>
        </ol>

        <h3>第１４条（個人情報の取扱い）</h3>
        <ol>
          <li>受託者は、本業務を遂行するにあたり「個人情報の保護に関する法律」第２条第１項に定める個人情報（以下「個人情報」という）を取り扱う場合には、同法及びその他各種ガイドラインを遵守しなければならない。</li>
          <li>受託者は、当社から取り扱いを委託された個人情報について、本業務遂行の目的以外に使用してはならず、当社の事前の書面による承諾なく第三者に提供してはならない。</li>
        </ol>

        <h3>第１５条（反社会的勢力の排除）</h3>
        <ol>
          <li>
            当社及び受託者は、相手方に対し、本契約締結時において、自ら（法人の場合は、代表者、役員又は実質的に経営を支配する者）が暴力団、暴力団員、暴力団若しくは暴力団員ではなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動標ぼうゴロ又は特殊知能暴力集団その他これらに準ずる者（以下、これらを「反社会的勢力」という。）に該当しないこと、及び以下の各号のいずれにも該当しないことを表明し、かつ将来にわたって該当しないことを確約する。
            <br>
            <span class="indent">⑴ 反社会的勢力が経営を支配していると認められる関係を有すること</span>
            <br>
            <span class="indent">⑵ 反社会的勢力が経営に実質的に関与していると認められる関係を有すること</span>
            <br>
            <span class="indent">⑶ 自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を与える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有すること</span>
            <br>
            <span class="indent">⑷ 反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること</span>
            <br>
            <span class="indent">⑸ 役員又は経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること</span>
          </li>
          <li>
            当社及び受託者は、自ら又は第三者を利用して以下の各号のいずれかに該当する行為を行わないことを確約する。
            <br>
            <span class="indent">⑴ 暴力的な要求行為</span>
            <br>
            <span class="indent">⑵ 法的な責任を超えた不当な要求行為</span>
            <br>
            <span class="indent">⑶ 取引に関して、強迫的な言動をし、又は暴力を用いる行為</span>
            <br>
            <span class="indent">⑷ 風説を流布し、偽計を用い若しくは威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為</span>
            <br>
            <span class="indent">⑸ その他前各号に準ずる行為</span>
          </li>
          <li>当社又は受託者は、相手方が第１項又は第２項の表明又は確約に違反した場合には、催告その他の手続を要しないで、直ちに本契約を解除することができる。</li>
          <li>当社又は受託者は、前項により本契約を解除した場合には、相手方に対し、一切の責任を負わない。</li>
          <li>第３項により当社又は受託者が契約を解除した場合には、解除した当事者から相手方に対する損害賠償請求を妨げない。</li>
        </ol>

        <h3>第１６条（損害賠償）</h3>
        <p>
          受託者は、故意又は過失により本契約に違反し、当社に損害を与えた場合には、その一切の損害を賠償するものとし、その請求のために当社が弁護士に委任したときは、合理的な弁護士費用も負担しなければならない。
        </p>

        <h3>第１７条（免責）</h3>
        <p>
          当社は、受託者から紹介された顧客との間の本利用契約について、その締結及び終了の可否を自由に判断できるものとし、これらの判断について、受託者に対し、一切の責任を負わない。
        </p>

        <h3>第１８条（契約期間）</h3>
        <p>本契約の有効期間は、契約締結の日から１年間とする。但し、期間満了の３か月前までに、当社又は受託者から書面による特段の申し出がない場合には、本契約と同一条件でさらに１年間継続し、以後も同様とする。</p>

        <h3>第１９条（中途解約）</h3>
        <ol>
          <li>当社又は受託者は、本契約期間中であっても、１か月前までに書面で申し出ることにより、いつでも本契約を中途解約することができる。</li>
          <li>当社又は受託者は、前項による中途解約によって相手方に生じた損害について、一切の責任を負わない。</li>
        </ol>

        <h3>第２０条（契約解除）</h3>
        <ol>
          <li>
            当社又は受託者は、相手方が次の各号のいずれかに該当した場合には、催告その他の手続を要しないで、直ちに本契約を解除することができる。
            <br>
            <span class="indent">⑴ 監督官庁より営業の取消し、停止等の処分を受けたとき</span>
            <br>
            <span class="indent">⑵ 支払停止若しくは支払不能の状態に陥ったとき、又は手形交換所から警告若しくは不渡り処分を受けたとき</span>
            <br>
            <span class="indent">⑶ 信用資力の著しい低下があったとき、又はこれに影響を及ぼす営業上の重要な変更があったとき</span>
            <br>
            <span class="indent">⑷ 第三者より差押え、仮差押え、仮処分、その他強制執行若しくは競売の申立て、又は公租公課の滞納処分を受けたとき</span>
            <br>
            <span class="indent">⑸ 破産手続開始、民事再生手続開始、会社更生手続開始の申立て等がなされたとき</span>
            <br>
            <span class="indent">⑹ 解散の決議をし、又は他の会社と合併したとき</span>
            <br>
            <span class="indent">⑺ 災害、労働争議等、本契約の履行を困難にする事項が生じたとき</span>
            <br>
            <span class="indent">⑻ 相手方に対する詐術その他の背信的行為があったとき</span>
          </li>
          <li>当社又は受託者は、相手方が本契約の各条項に違反し、相当の期間をおいて催告したにもかかわらず是正しない場合には、本契約を解除することができる。</li>
        </ol>

        <h3>第２１条（支給物の返却）</h3>
        <p>受託者は、本契約が終了した場合には、当社より提供を受けた本資料等を速やかに当社に返却しなければならず、また、本デモ画面の使用をしてはならない。</p>

        <h3>第２２条（存続条項）</h3>
        <p>本契約終了後も、第６条第６項乃至第９項、第１０条、第１３条、第１４条、第１６条、第１７条、第２１条、第２３条、第２４条及び第２５条は有効に存続する。</p>

        <h3>第２３条（分離可能性条項）</h3>
        <p>本契約の条項の一部が、理由の如何にかかわらず、無効又は違法と判断された場合においても、本契約のその余の規定の有効性及び適法性は、そのことにより一切影響を受けないものとする。</p>

        <h3>第２４条（協議解決）</h3>
        <p>本契約に定めのない事項、又は本契約の解釈について疑義が生じた場合には、当社及び受託者は、誠意をもって協議のうえ解決する。</p>

        <h3>第２５条（合意管轄）</h3>
        <p>本契約に関する紛争については、岐阜地方裁判所を第一審の専属的合意管轄裁判所とする。</p>

        <p class="end">以上</p>
        <p class="terms">【２０２４年６月１７日制定】</p>
      </div>
    </section>
    <TopButtonVue class="top" />
  </div>
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import TopButtonVue from '@/components/Ui/TopButton.vue';

export default {
  name: "PartnerTerm",
  components: {
    Top,
    TopButtonVue,
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
section {
  .wrap {
    .desc-sub-title {
      font-size: 16px;
      font-weight: 300;
      color: var(--font);
      line-height: 1.5rem;
      text-indent: 1rem;
    }
    h3 {
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    ol {
      @media screen and (max-width: 767px) {
        padding-inline-start: 25px;
      }
      li {
        line-height: 1.5rem;
        margin-bottom: 5px;
      }
    }
    p {
      text-indent: 1rem;
    }
    .indent {
      margin-left: 1rem;
    }
    .end {
      text-indent: 0;
      text-align: end;
      margin-top: 20px;
    }
    .terms {
      text-indent: 0;
      margin-top: 20px;
    }
  }
}
.top {
  margin: 20px auto;
}
</style>