<template>
  <section id="ad-fee">
    <div class="wrap">
      <h2>持続した広告配信で<br class="sp-only">ブランディング</h2>
      <h3>
        利用料金
      </h3>
      <PriceTableVue />
      <a class="movie-link" v-scroll-to="'#contact'">登録はこちら</a>
    </div>
  </section>
</template>

<script>
import PriceTableVue from '@/components/Ui/PriceTable.vue';

export default {
  name: "AdBranding",
  components: {
    PriceTableVue,
  }
}
</script>

<style lang="scss" scoped>
section {
  .wrap {
    h2 {
      text-align: center;
      margin: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
    h3 {
      font-size: 32px;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        position: relative;
        font-size: 20px;
        margin: 20px 0;
      }
    }
    /* 登録はこちら */
    .movie-link {
      display: block;
      width: 30%;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      padding: 15px 0;
      margin: 50px auto 0 auto;
      color: var(--font);
      border: 1px solid var(--font);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 50%;
        padding: 10px 0;
        margin-top: 40px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 10px 0;
        margin: 20px auto 0 auto;
      }
      &:hover {
        color: var(--white);
        background-color: var(--sub1);
        border: 1px solid var(--sub1);
      }
    }
  }
}
</style>