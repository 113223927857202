<template lang="">
  <div class="top-img">
    <img class="pc-tablet-only" src="@/assets/top/top.webp" alt="始めよう、ミナティ。" />
    <img class="sp-only" src="@/assets/top/sp_top.webp" alt="始めよう、ミナティ。" />
  </div>
</template>

<script>
export default {
  name: "AppVue",
}
</script>

<style lang="scss" scoped>
.top-img {
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 1101px) {
    width: 100%;
    height: 100vh;
    background-color: var(--white);
  }
  @media screen and (min-width: 768px) and (max-width: 1600px) {
    margin-top: 8vh;
    width: 100%;
    height: auto;
    background-color: var(--sub2);
  }
  @media screen and (max-width: 767px) {
    height: 90vh;
    margin-top: 10vh;
    background-color: var(--sub2);
  }
  img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 92vh;
    object-fit: cover;
    z-index: 0;
    @media screen and (min-width: 768px) and (max-width: 1600px) {
      position: static;
      width: 100%;
      height: auto;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: auto;
      top: 0;
      bottom: auto;
    }
  }
}
</style>