<template>
  <div class="header"></div>
  <Top title="会社概要" />
  <AboutUs />
  <CompanyProfile />
  <DescriptionLink  class="mt-40px"/>
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import AboutUs from '@/components/info/AboutUs.vue';
import CompanyProfile from '@/components/info/CompanyProfile.vue';
import DescriptionLink from '@/components/Ui/DescriptionLink.vue';

export default {
  name: "InfoVue",
  components: {
    Top,
    AboutUs,
    CompanyProfile,
    DescriptionLink,
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
</style>