<template>
  <div class="header"></div>
  <Top title="パートナー制度" />
  <Solicitation />
  <WhatPartner />
  <SupportSystem />
  <Incentive />
  <RegistrationFlow />
  <PartnerFaqContent />
  <ContactVue />
  <TopButtonVue />
  <DescriptionLink />
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import Solicitation from '@/components/partner/Solicitation.vue';
import WhatPartner from '@/components/partner/WhatPartner.vue';
import SupportSystem from '@/components/partner/SupportSystem.vue';
import Incentive from '@/components/partner/Incentive.vue';
import RegistrationFlow from '@/components/partner/RegistrationFlow.vue';
import ContactVue from '@/components/top/Contact.vue';
import TopButtonVue from '@/components/Ui/TopButton.vue';
import DescriptionLink from '@/components/Ui/DescriptionLink.vue';
import PartnerFaqContent from '@/components/faq/PartnerContent.vue';


export default {
  name: "PartnerVue",
  components: {
    Top,
    Solicitation,
    WhatPartner,
    SupportSystem,
    Incentive,
    RegistrationFlow,
    ContactVue,
    DescriptionLink,
    TopButtonVue,
    PartnerFaqContent
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
</style>