<template>
  <section id="system-features">
    <div class="wrap">
      <h2>システム機能</h2>
      <div class="features">
        <div class="feature">
          <div class="border-gold">
            <img src="@/assets/top/gazo006.webp">
            <h3>顧客管理システム</h3>
          </div>
          <p class="point-message">顧客管理はスマホの時代！<br>QRコードでスマートに</p>
          <p class="description-message">
            会員カード・ポイントカード不要！
            <br>
            スマホで管理
          </p>
          <p>ミナティ加盟店ならどの店舗でも、会員顧客様は自分の会員証ＱＲコードを表示するだけです。</p>
          <p>店舗は会員証を読取るだけでカンタンに会員情報の確認とポイント処理ができます。</p>
        </div>
        <div class="feature margin-top">
          <div class="border-gold">
            <img src="@/assets/top/gazo005.webp">
            <h3>ポイントシステム</h3>
          </div>
          <p class="point-message">オリジナルポイントで<br>差をつける！</p>
          <p class="description-message">
            リピート率を上げるために・・・
            <br>
            メニュー・商品・お祝いなどにポイントを
          </p>
          <p>会員顧客様は登録済み店舗情報を一覧表示。</p>
          <p>貯まったポイントは店舗別に確認できます。</p>
          <p>その店舗でしか使えないオリジナルポイントを顧客へ付与して来店を促しましょう。</p>
          <div class="point">
            <img src="@/assets/top/icon002.webp" class="check-img">
            <p>会員カード・ポイントカードを忘れやすい</p>
          </div>
          <div class="point">
            <img src="@/assets/top/icon002.webp" class="check-img">
            <p>サロン・ショップのアプリを探すのが大変</p>
          </div>
          <div class="point">
            <img src="@/assets/top/icon002.webp" class="check-img">
            <p>新しい店舗の会員登録が面倒</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SystemFeatures"
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--white);
  .wrap {
    h2 {
      text-align: center;
      line-height: 50px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        font-size: 24px;
        line-height: 40px;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 35px;
      }
    }
    .features {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .feature {
        width: 48%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .border-gold {
          border: 5px solid var(--sub1);
          h3 {
            font-size: 24px;
            font-weight: 500;
            padding: 20px 0 20px 20px;
            margin: 0;
            color: var(--white);
            background-color: var(--sub1);
            @media screen and (max-width: 767px) {
              font-size: 20px;
            }
          }
        }
        .point-message {
          font-size: 32px;
          font-weight: 500;
          line-height: 40px;
          margin: 20px 0;
          color: var(--main);
          @media screen and (max-width: 767px) {
            font-size: 23px;
            line-height: 30px;
          }
        }
        .description-message {
          font-size: 23px;
          font-weight: 500;
          line-height: 33px;
          color: var(--font);
          margin: 20px 0;
          @media screen and (max-width: 1100px) {
            font-size: 18px;
            line-height: 28px;
            margin: 15px 0;
          }
        }
        .point {
          display: flex;
          align-items: center;
          margin: 15px 0;
          .check-img {
            width: 25px;
            height: 25px;
          }
          p {
            font-size: 18px;
            margin-left: 10px;
            @media screen and (max-width: 1100px) {
              font-size: 16px;
            }
          }
        }
      }
      .margin-top {
        @media screen and (max-width: 767px) {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>