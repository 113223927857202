<template>
  <div class="header"></div>
  <Top title="広告掲載" />
  <AdvertiseWithMinaty />
  <ContactVue />
  <TopButtonVue />
  <DescriptionLink  class="mt-40px"/>
</template>

<script>
import Top from '@/components/Ui/Top.vue';
import AdvertiseWithMinaty from '@/components/advertisements/AdvertiseWithMinaty.vue';
import ContactVue from '@/components/top/Contact.vue';
import TopButtonVue from '@/components/Ui/TopButton.vue';
import DescriptionLink from '@/components/Ui/DescriptionLink.vue';

export default {
  name: "AdvertisementsVue",
  components: {
    Top,
    AdvertiseWithMinaty,
    ContactVue,
    TopButtonVue,
    DescriptionLink,
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 4vh;
  @media screen and (max-width: 767px) {
    padding: 5vh;
  }
}
</style>