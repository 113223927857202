<template>
  <Header />
  <router-view/>
  <img
    src="@/assets/top/icon.webp"
    alt="TOPへ戻る"
    class="top-scroll"
    v-if="showScrollButton"
    v-scroll-to="'#app'"
  >
  <Footer />
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showScrollButton: false,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showScrollButton = scrollTop > 200;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

/*---------------------
 共通初期設定
---------------------*/
:root {
  --font: #000000;
  --main: #EF9400;
  --sub1: #B69067;
  --sub2: #FBF3ED;
  --sub3: #DFDFDF;
  --marker: rgb(239, 148, 0, 0.3);
  --gold: #DFCAA3;
  --white: #FFFFFF;
}

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: var(--font);
  padding: 0;
  margin: 0;
  @media screen and (max-width: 767px) {
    line-height: 1.6em;
  }
}

html * {
  box-sizing: border-box !important;
}

body {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

a {
  text-decoration: none;
  color: var(--font);
}

a,
button,
select {
  cursor: pointer;
}

/*---------------------
 display 設定
---------------------*/
.pc-only {
  @media screen and (max-width: 1100px) {
    display: none;
  }
}
.pc-tablet-only {
  display: none;
  @media screen and (min-width: 768px) {
    display: initial !important;
  }
}
.tablet-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    display: initial !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

/*---------------------
 Heading, Paragraph
---------------------*/
h1,
h2 {
  font-size: 35px;
  line-height: 35px;
  font-weight: 500;
  color: var(--main);
  @media screen and (max-width: 767px) {
    font-size: 23px;
  }
}
h3 {
  font-size: 22px;
  line-height: 22px;
  color: var(--font);
}
p {
  margin: 0;
}
/*---------------------
 Section
---------------------*/
section {
  padding: 160px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 12vh 0;
  }
}
.wrap {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content {
    padding: 60px 0;
    @media screen and (max-width: 1100px) {
      padding: 30px 20px;
      width: 100%;
      margin: 0;
    }
  }
}
/*---------------------
 TOPへ戻るスクロールボタン
---------------------*/
.top-scroll {
  width: 70px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  opacity: 0.9;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 50px;
    right: 0;
    bottom: 60px;
  }
}
</style>
