<template>
  <section>
    <div class="wrap">
      <div class="selling-points">
        <div class="points">
          <div class="point">
            <img src="@/assets/top/icon002.webp" class="check-img">
            <p>特許技術による圧倒的な集客効果</p>
          </div>
          <div class="point">
            <img src="@/assets/top/icon002.webp" class="check-img">
            <p>「業界初」顧客がインフルエンサー</p>
          </div>
          <div class="point">
            <img src="@/assets/top/icon002.webp" class="check-img">
            <p>SNSで紹介広告を拡散！</p>
          </div>
        </div>
        <img src="@/assets/top/icon003.webp" alt="MINATYの実績" class="point-img pc-img">
        <img src="@/assets/top/icon003_sp.webp" alt="MINATYの実績" class="point-img sp-only">
      </div>
      <h2>
        ミナティがSNSで
        <br class="sp-only">
        未来の扉を開く
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: "SellingPoint",
}
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 80px;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 767px) {
    padding: 6vh 0;
  }
  .wrap {
    h2 {
      text-align: center;
      margin: 40px auto 0 auto;
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
  .selling-points {
    margin-top: 60px;
    display: block;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
    .points {
      width: 40%;
      margin: auto;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
      .point {
        display: flex;
        align-items: center;
        margin: 20px 0;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 60%;
          margin: 20px auto;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .check-img {
          width: 30px;
          height: 30px;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 50px;
            height: 50px;
          }
          @media screen and (max-width: 767px) {
            width: 25px;
            height: 25px;
          }
        }
        p {
          font-size: 22px;
          margin-left: 10px;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
      }
    }
    .point-img {
      width: 100%;
      margin: 0 auto;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 100%;
        margin: 40px auto 0 auto;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }
    }
    .pc-img {
      display: block;
      margin: 60px auto 60px auto;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
</style>
